import * as actionsType from "./actionsType";
import * as requestFromServer from "../../app/network/api";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const createSupplier = (name) => {
  return {
    type: actionsType.CREATE_SUPPLIER,
    payload: name,
  };
};

export const fetchSupplierSuccess = (fetchedCat) => {
  return {
    type: actionsType.FETCH_SUPPLIER_SUCCESS,
    payload: fetchedCat,
  };
};

export const initSupplier = (initFetch) => {
  return {
    type: actionsType.INIT_SUPPLIER,
    payload: initFetch,
  };
};

export const updateSupplier = (data) => {
  return {
    type: actionsType.UPADTE_SUPPLIER,
    payload: data,
  };
};

export const isLoading = (data) => {
  return {
    type: actionsType.IS_LOADING,
    payload: data,
  };
};

export const setTotalSupplierCount = (data) => {
  return {
    type: actionsType.TOTALSUPPLIERCOUNT,
    payload: data,
  };
};

export const updateSupplierCell = (data) => {
  return {
    type: actionsType.UPADTE_SUPPLIER_CELL,
    payload: data,
  };
};
