import * as actionsType from "./actionsType";

export const updateNewOrderTime = (ordertime) => {
  return {
    type: actionsType.NEW_ORDER_TIME,
    payload: ordertime,
  };
};

export const previousOrderTime = (ordertime) => {
  return {
    type: actionsType.PREVIOUS_ORDER_TIME,
    payload: ordertime,
  };
};

export const NotificationOrder = (orders) => {
  return {
    type: actionsType.NOTIFICATION_ORDERS,
    payload: orders,
  };
};
