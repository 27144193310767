import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import Box from "@material-ui/core/Box";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector, shallowEqual } from "react-redux";
import SalesManagementTable from "./SalesManagementTable";
import {
  makeStyles,
  TextField,
  IconButton,
  Button,
  fade,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import * as requestfromServer from "../../network/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Html5Entities } from "html-entities";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import "moment-timezone";
const entities = new Html5Entities();
const SalesManagement = () => {
  const { t } = useTranslation(["common", "salesmanagement"]);
  const suhbeader = useSubheader();
  const [From, setFrom] = useState(
    moment()
      .startOf("month")
      .toISOString()
  );
  const [To, setTo] = useState(
    moment()
      .endOf("month")
      .toISOString()
  );
  suhbeader.setTitle(`${t("salesmanagement:salesMangement")}`);
  const [state, setstate] = useState({
    amazonCommission: 0,
    customFee: 0,
    noOfOrders: 0,
    profit: 0,
    salesValue: 0,
    shippingFee: 0,
    totalCostOfGoods: 0,
  });
  const onChange = (from, to) => {
    console.log("sales from" + from);
    console.log("sales to" + to);
    setFrom(from);
    setTo(to);
  };
  useEffect(() => {
    requestfromServer
      .ordersTotal(
        moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      )
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data) {
          setstate({
            ...state,
            amazonCommission: res.data.data.amazonCommission,
            customFee: res.data.data.customFee,
            noOfOrders: res.data.data.noOfOrders,
            profit: res.data.data.profit,
            salesValue: res.data.data.salesValue,
            shippingFee: res.data.data.shippingFee,
            totalCostOfGoods: res.data.data.totalCostOfGoods,
          });
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, [To, From]);

  const changeSalesCardValue = (from, to) => {
    requestfromServer.ordersTotal(from, to).then((res) => {
      if (res.data.data) {
        setstate({
          ...state,
          amazonCommission: res.data.data.amazonCommission,
          customFee: res.data.data.customFee,
          noOfOrders: res.data.data.noOfOrders,
          profit: res.data.data.profit,
          salesValue: res.data.data.salesValue,
          shippingFee: res.data.data.shippingFee,
          totalCostOfGoods: res.data.data.totalCostOfGoods,
        });
      }
    });
  };

  return (
    <>
      <div>
        {/* Grid */}

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                  ></SVG>
                </span>
                <span
                  className="svg-icon svg-icon-3x svg-icon-warning d-block my-5
                "
                >
                  <span className="text-warning font-weight-bold font-size-h6">
                    <Typography variant="h5" component="h2">
                      {/* 利益 */}
                      {state.noOfOrders && Math.ceil(state.noOfOrders)}
                    </Typography>
                  </span>
                </span>
              </div>
              <a className="text-warning font-weight-bold font-size-h6">
                <Typography variant="h6" component="h2">
                  {/* 利益 */}
                  {t("salesmanagement:orderCount")}
                </Typography>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Map/Direction1.svg")}
                  ></SVG>
                </span>
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-5">
                  <span className="text-primary font-weight-bold font-size-h6 mt-2">
                    <Typography variant="h5" component="h2">
                      {/* 利益 */}
                      {entities.decode("&yen")}{" "}
                      {state.shippingFee && Math.ceil(state.shippingFee)}
                    </Typography>
                  </span>
                </span>
              </div>
              <a
                href="#"
                className="text-primary font-weight-bold font-size-h6 mt-2"
              >
                <Typography variant="h6" component="h2">
                  {/* 利益 */}
                  {t("salesmanagement:shippingFee")}
                </Typography>
              </a>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Flatten.svg")}
                  ></SVG>
                </span>
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-5">
                  <span className="text-danger font-weight-bold font-size-h6 mt-2">
                    <Typography variant="h5" component="h2">
                      {/* 利益 */}
                      {entities.decode("&yen")}{" "}
                      {state.amazonCommission &&
                        Math.ceil(state.amazonCommission)}
                    </Typography>
                  </span>
                </span>
              </div>
              <a
                href="#"
                className="text-danger font-weight-bold font-size-h6 mt-2"
              >
                <Typography variant="h6" component="h2">
                  {/* 〒 */}
                  {t("salesmanagement:amzComsn")}
                </Typography>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="col bg-light-info px-6 py-8 rounded-xl">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Calculator.svg"
                    )}
                  ></SVG>
                </span>
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-5">
                  <span className="text-info font-weight-bold font-size-h6 mt-2">
                    <Typography variant="h5" component="h2">
                      {/* 利益 */}
                      {entities.decode("&yen")}{" "}
                      {state.customFee && Math.ceil(state.customFee)}
                    </Typography>
                  </span>
                </span>
              </div>
              <a
                href="#"
                className="text-info font-weight-bold font-size-h6 mt-2"
              >
                <Typography variant="h6" component="h2">
                  {/* 梱包料 */}
                  {t("salesmanagement:customFee")}
                </Typography>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet2.svg")}
                  ></SVG>
                </span>
                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-5">
                  <span className="text-success font-weight-bold font-size-h6">
                    <Typography variant="h5" component="h2">
                      {/* 利益 */}
                      {entities.decode("&yen")}
                      {state.profit && Math.ceil(state.profit)}
                    </Typography>
                  </span>
                </span>
              </div>
              <a
                href="#"
                className="text-success font-weight-bold font-size-h6"
              >
                <Typography variant="h6" component="h2">
                  {/* 仕入額 */}
                  {t("salesmanagement:profit")}
                </Typography>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="col bg-light-warning px-6 py-8 rounded-xl">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Chart-bar1.svg"
                    )}
                  ></SVG>
                </span>

                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-5">
                  <span className="text-warning font-weight-bold font-size-h6 mt-2">
                    <Typography variant="h5" component="h2">
                      {/* 利益 */}
                      {entities.decode("&yen")}{" "}
                      {state.salesValue && Math.ceil(state.salesValue)}
                    </Typography>
                  </span>
                </span>
              </div>
              <a
                href="#"
                className="text-warning font-weight-bold font-size-h6 mt-2"
              >
                <Typography variant="h6" component="h2">
                  {/* 利益 */}
                  {t("salesmanagement:salesValue")}
                </Typography>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Attachment2.svg"
                    )}
                  ></SVG>
                </span>
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-5">
                  <span className="text-primary font-weight-bold font-size-h6 mt-2">
                    <Typography variant="h5" component="h2">
                      {/* 利益 */}
                      {entities.decode("&yen")}
                      {state.totalCostOfGoods &&
                        Math.ceil(state.totalCostOfGoods)}
                    </Typography>
                  </span>
                </span>
              </div>
              <a
                href="#"
                className="text-primary font-weight-bold font-size-h6 mt-2"
              >
                <Typography variant="h6" component="h2">
                  {/* 利益 */}
                  {t("salesmanagement:goodsCost")}
                </Typography>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}></Grid>
        </Grid>
        {/* GRID */}
        {/*  */}
        <div>
          <Card style={{ paddingTop: "20px" }}>
            <SalesManagementTable ChangeSalesCardValue={changeSalesCardValue} />
          </Card>
        </div>
      </div>
    </>
  );
};

export default SalesManagement;
