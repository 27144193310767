import * as actionType from "../actions/actionsType";

const initialState = {
  shippingMethod: [],
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_SHIPPINGMETHOD:
      return {
        ...state,
        shippingMethod: action.payload,
      };
    case actionType.INIT_SHIPPINGMETHOD:
      return {
        ...state,
        shippingMethod: state.shippingMethod
          .concat(action.payload)
          .sort((a, b) => (a.id > b.id ? 1 : -1)),
      };
    case actionType.UPADTE_SHIPPINGMETHOD:
      return {
        ...state,
        shippingMethod: state.shippingMethod.map((p) => {
          console.log(JSON.stringify(action));
          return p.id === action.id
            ? { ...p, name: action.name, active_status: action.active_status }
            : p;
        }),
      };
    case actionType.TOTALSHIPPINGMETHODCOUNT:
      return {
        ...state,
        totalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
