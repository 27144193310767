import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../../../config";

const API_ENDPOINT = BASE_URL;

const getDataForCSV = () => {
  return axios.get(`${API_ENDPOINT}/products/csv-products`);
};

const getData = (page, limit) => {
  return axios.get(
    `${API_ENDPOINT}/products?page=${page}&limit=${limit}&sort=-updatedAt`
  );
};
const getTableLength = () => {
  return axios.get(`${API_ENDPOINT}/products?limit=20000`);
};

const saveProduct = (product) => {
  //Take ID out of value and save the new value data to db
  return axios.post(`${API_ENDPOINT}/products`, product);
};
const updateProductRow = (product, id) => {
  //Take the id and update in DB
  return axios.put(`${API_ENDPOINT}/products/${id}`, { ...product });
};

const SearchProductTable = (queryParams) => {
  //Just for the search field
  return axios.get(
    `${API_ENDPOINT}/products/search?searchString=${queryParams}&page=1&limit=25&sort=-updatedAt&activeStatus=true`
  );
};

const SearchProductTableQueryParams = (queryParams, searchString) => {
  //Just for the search field
  return axios.get(
    `${API_ENDPOINT}/products/?&page=1&limit=25&sort=-updatedAt&${queryParams}=${searchString}`
  );
};

const getSupplierNames = (supplierArray) => {
  const promises = supplierArray.map((supplierId) => {
    return axios.get(`${API_ENDPOINT}/suppliers/${supplierId["_id"]}`);
  });
  return Promise.all(promises).then((results) => {
    return results;
  });
};

const postRemarks = (productId, Remarks) => {
  //Just for the search field
  return axios.post(`${API_ENDPOINT}/products/${productId}/remarks`, Remarks);
};

const sortData = (lowstock, status, sortField, sortOrder, limit, page) => {
  if (status === "Active") {
    if (lowstock) {
      if (sortField) {
        if (sortOrder === "asc") {
          return axios.get(
            `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=-${sortField}&activeStatus=true&lowStock=true`
          );
        } else {
          return axios.get(
            `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=${sortField}&activeStatus=true&lowStock=true`
          );
        }
      }
      return axios.get(
        `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&activeStatus=true&lowStock=true`
      );
    }

    if (sortField) {
      if (sortOrder === "asc") {
        return axios.get(
          `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=-${sortField}&activeStatus=true`
        );
      } else {
        return axios.get(
          `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=${sortField}&activeStatus=true`
        );
      }
    }
    //else no sortfield
    return axios.get(
      `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&activeStatus=true`
    );
  }

  if (status === "InActive") {
    if (lowstock) {
      if (sortField) {
        if (sortOrder === "asc") {
          return axios.get(
            `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=-${sortField}&activeStatus=false&lowStock=true`
          );
        } else {
          return axios.get(
            `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=${sortField}&activeStatus=false&lowStock=true`
          );
        }
      }
      return axios.get(
        `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&activeStatus=false&lowStock=true`
      );
    }
    if (sortField) {
      if (sortOrder === "asc") {
        return axios.get(
          `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=-${sortField}&activeStatus=false`
        );
      } else {
        return axios.get(
          `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=${sortField}&activeStatus=false`
        );
      }
    }
    return axios.get(
      `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&activeStatus=false`
    );
  }

  if (lowstock) {
    if (sortField) {
      if (sortOrder === "asc") {
        return axios.get(
          `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=-${sortField}&lowStock=true`
        );
      } else {
        return axios.get(
          `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=${sortField}&lowStock=true`
        );
      }
    }
    return axios.get(
      `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&lowStock=true`
    );
  }

  if (sortField) {
    if (sortOrder === "asc") {
      return axios.get(
        `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=-${sortField}`
      );
    } else {
      return axios.get(
        `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=${sortField}`
      );
    }
  }
  return axios.get(
    `${API_ENDPOINT}/products/?page=${page}&limit=${limit}&sort=-updatedAt`
  );
};

const fetchCategory = (page, sizePerPage) => {
  return axios.get(`${API_ENDPOINT}/categories/?limit=100&sort=name`);
};

const fetchManufacturer = () => {
  return axios.get(`${API_ENDPOINT}/manufacturers?limit=100&sort=name`);
};

const fetchSuppliers = () => {
  return axios.get(`${API_ENDPOINT}/suppliers?limit=100&sort=name`);
};

const createOrder = (data) => {
  return axios.post(`${API_ENDPOINT}/orders`, data);
};

const UpdateProduct = (productid, data) => {
  return axios.put(`${API_ENDPOINT}/products/${productid}`, data);
};

export {
  getDataForCSV,
  createOrder,
  getData,
  getTableLength,
  updateProductRow,
  saveProduct,
  SearchProductTable,
  getSupplierNames,
  postRemarks,
  sortData,
  fetchCategory,
  fetchManufacturer,
  fetchSuppliers,
  UpdateProduct,
  SearchProductTableQueryParams,
};
