/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  isEcOrder,
  isOtzOrder,
} from "../../../../../redux/actions/Notification";
import { Height } from "@material-ui/icons";
import { INSTANCE } from "../../../../../config";

// -----------------------------------------------------------
import Badge from "@material-ui/core/Badge";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiBadge-root": {
      marginRight: theme.spacing(4),
    },
  },
}));

// -----------------------------------------------------------

export function AsideMenuList({ layoutProps }) {
  // ---------------------------------------------
  const classes = useStyles();
  // ---------------------------------------------

  const { t } = useTranslation(["common"]);
  const { isAdmin } = useSelector(
    ({ auth }) => ({
      isAdmin: auth.user === "admin",
    }),
    shallowEqual
  );
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open"
      : "";
  };

  const { isOrderCreator } = useSelector(
    ({ auth }) => ({
      isOrderCreator: auth.user === "orderCreator",
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const VisitedAsideMenu = () => {
    dispatch(isEcOrder(false));
  };
  const VisitedOtzAsideMenu = () => {
    dispatch(isOtzOrder(false));
  };
  const { isOrder } = useSelector(
    ({ EcOrder }) => ({
      isOrder: EcOrder.newOrder === true,
    }),
    shallowEqual
  );
  console.log(INSTANCE);
  console.log("TESTING CONSOLE");

  const { isOtzOrders } = useSelector(
    ({ EcOrder }) => ({
      isOtzOrders: EcOrder.newOTZOrder === true,
    }),
    shallowEqual
  );

  return (
    <>
      {/* begin::Menu Nav */}
      {isAdmin ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}

          <li
            className={`menu-item ${getMenuItemActive("/shipping-management")}`}
            aria-haspopup="true"
            onClick={VisitedAsideMenu}
          >
            <NavLink className="menu-link" to="/shipping-management">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Incoming-box.svg"
                  )}
                />
              </span>
              {/* <span className="menu-text">出荷管理</span> */}
              <span className="menu-text">{t("common:ordermanagement")}</span>
              {/* <span
                style={{
                  Height: "3px",
                  width: "3px",
                  backgroundColor: "#bbb",
                  borderRadius: "50%",
                  display: "inline",
                  marginRight: "100px",
                }}
              ></span> */}

              {isOrder && (
                <Badge
                  color="secondary"
                  variant="dot"
                  style={{ marginRight: "50%" }}
                />
              )}
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/order-amalgamation")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/order-amalgamation">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Commode2.svg")}
                />
              </span>
              <span className="menu-text">{t("common:combineorder")}</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/product")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/products">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">{t("common:products")}</span>
            </NavLink>
          </li>

          {/*begin::1 Level*/}

          {INSTANCE !== "ZOAITEC" && (
            <li
              className={`menu-item ${getMenuItemActive("/otz")}`}
              aria-haspopup="true"
              onClick={VisitedOtzAsideMenu}
            >
              <NavLink className="menu-link" to="/otz">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Incoming-box.svg"
                    )}
                  />
                </span>

                <span className="menu-text">{t("common:otzorders")}</span>
                {isOtzOrders && (
                  <Badge
                    color="secondary"
                    variant="dot"
                    style={{ marginRight: "50%" }}
                  />
                )}
              </NavLink>
            </li>
          )}

          <li
            className={`menu-item ${getMenuItemActive("/sales-management")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sales-management">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")}
                />
              </span>
              <span className="menu-text">{t("common:salesmanagement")}</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/issues")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/issues">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">{t("common:issues")}</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/AdvanceOrder")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/AdvanceOrder">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Food/Bucket.svg")} />
              </span>
              <span className="menu-text">{t("common:advOrder")}</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/Simulator")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Simulator">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Calculator.svg"
                  )}
                />
              </span>
              <span className="menu-text">{t("common:Simulator")}</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/newproductrequest")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/newproductrequest">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                />
              </span>
              <span className="menu-text">{t("common:productrequest")}</span>
            </NavLink>
          </li>
          {/*end::1 Level

          {INSTANCE !== "ZOAITEC" && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/ot-sales-management"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/ot-sales-management">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")}
                  />
                </span>
                <span className="menu-text">{t("common:otzsales")}</span>
              </NavLink>
            </li>
          )}

          {/* Components */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">{t("common:settings")}</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* Material-UI */}
          {/*begin::1 Level*/}

          <li
            className={`menu-item ${getMenuItemActive("/settings")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/settings">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">{t("common:settings")}</span>
            </NavLink>
          </li>

          {INSTANCE === "ZOAITEC" && (
            <li
              className={`menu-item ${getMenuItemActive("/orderentryform")}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/orderentryform">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
                <span className="menu-text">{t("common:order_entry")}</span>
              </NavLink>
            </li>
          )}
          {INSTANCE === "ZOAITEC" && (
            <li
              className={`menu-item ${getMenuItemActive("/shipment")}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/shipment">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")}
                  />
                </span>
                <span className="menu-text">{t("common:shipments")}</span>
              </NavLink>
            </li>
          )}
        </ul>
      ) : null}

      {isOrderCreator ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}

          <li
            className={`menu-item ${getMenuItemActive("/orderentryform")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/orderentryform">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
              {/* <span className="menu-text">出荷管理</span> */}
              <span className="menu-text">{t("common:order_entry")}</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/shipment")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/shipment">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
              </span>
              <span className="menu-text">{t("common:shipments")}</span>
            </NavLink>
          </li>
        </ul>
      ) : null}

      {/* end::Menu Nav */}
    </>
  );
}
