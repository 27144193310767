import * as actionType from "../actions/actionsType";

const initialState = {
  lang: "ja",
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.NEW_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};

export default langReducer;
