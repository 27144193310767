import * as actionType from "../actions/actionsType";
import { compare } from "../../app/view/Settings/Components/Utils";

const initialState = {
  category: [],
  page: 1,
  sizePerPage: 10,
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.INIT_CATEGORY:
      return {
        ...state,
        category: state.category
          .concat(action.payload)
          .sort(compare)
          .reverse(),
      };
    case actionType.UPADTE_CATEGORY:
      return {
        ...state,
        category: state.category
          .concat(action.payload)
          .sort(compare)
          .reverse(),
      };

    case actionType.IS_LOADING:
      return {
        ...state,
        listLoading: action.payload,
      };
    case actionType.TOTALCOUNT:
      return {
        ...state,
        totalCount: action.payload,
      };

    case actionType.UPDATE_CATEGORY_CELL:
      return {
        ...state,
        category: action.payload,
      };
    case actionType.CREATE_CATEGORY:
      return {
        ...state,
        category: action.payload.sort(compare).reverse(),
      };

    default:
      return state;
  }
};

export default reducer;
