import * as actionsType from "./actionsType";
import * as requestFromServer from "../../app/network/api";

export const createHandlingCharge = (handlingchargename) => {
  return {
    type: actionsType.CREATE_HANDLING_CHARGES,
    payload: handlingchargename,
  };
};

export const initHandlingCharge = (initFetch) => {
  return {
    type: actionsType.INIT_HANDLING_CHARGES,
    payload: initFetch,
  };
};

export const updateHandlingChargeCell = (data) => {
  return {
    type: actionsType.UPADTE_HANDLING_CHARGES,
    payload: data,
  };
};

export const handlingChargetotalCount = (data) => {
  return {
    type: actionsType.TOTALHANDLINGCHARGESCOUNT,
    payload: data,
  };
};
