import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  IconButton,
  Button,
  NativeSelect,
  FormControl,
} from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Select,
} from "../../../../_metronic/_partials/controls";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import * as action from "../../../../redux/actions/index";
import { Formik, Form } from "formik";
import * as requestFromServer from "../../../network/api";
import { shallowEqual } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import HandlingChargesTable from "./HandlingChargesTable";
import { useTranslation } from "react-i18next";
export function HandlingCharges() {
  const { t } = useTranslation(["setting"]);
  const [Loading, setLoading] = useState(false);
  const [handlingChargeChange, sethandlingChargeChange] = useState("");
  const [typeChange, settypeChange] = useState("choose");
  const [value, setValueChange] = useState("");
  const [ShowTable, setShowTable] = useState(false);

  const { currentState } = useSelector((state) => ({
    currentState: state.handlingCharges,
  }));
  const dispatch = useDispatch();
  const { handlingcharges, totalCount } = currentState;

  useEffect(() => {
    requestFromServer.fetchHandlingChargeCount().then((count) => {
      dispatch(action.handlingChargetotalCount(count.data.totalCount));
      requestFromServer.fetchHandlingCharge(1, 10).then((handlingCharges) => {
        dispatch(action.createHandlingCharge(handlingCharges.data.data));
        setShowTable(true);
      });
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      handlingChargeChange !== "" &&
      value !== "" &&
      typeChange !== "choose"
    ) {
      let values = parseFloat(value);
      setLoading(true);
      requestFromServer
        .saveHandlingCharge(handlingChargeChange, typeChange, values)
        .then((res) => {
          dispatch(action.initHandlingCharge(res.data["data"]));
          //save to state and table
          requestFromServer.fetchHandlingChargeCount().then((count) => {
            dispatch(action.handlingChargetotalCount(count.data.totalCount));
            setLoading(false);
            sethandlingChargeChange("");
            settypeChange("choose");
            setValueChange("");
          });
        });
    }
  };

  const handlingChargeNameChange = (e) => {
    e.preventDefault();
    sethandlingChargeChange(e.target.value);
  };
  const ChangeType = (e) => {
    e.preventDefault();
    settypeChange(e.target.value);
  };
  const valueChange = (e) => {
    e.preventDefault();
    setValueChange(e.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
  }));

  const classes = useStyles();
  return (
    <div className="cat-wrapper row">
      <div className="col-4">
        <div className="cat-header">
          <h3>{t("setting:hc.hcCharge")}</h3>
        </div>
        <div className="cat-form">
          <Formik>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                id="outlined-textarea"
                label={t("setting:hc.enterName")}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="handlingChargeName"
                onChange={handlingChargeNameChange}
                value={handlingChargeChange}
                size="small"
              />

              <Select
                name="type"
                onChange={ChangeType}
                value={typeChange}
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <option value={"choose"}>{t("setting:hc.choose")}</option>
                <option value={"percentage"}>
                  {t("setting:hc.percentage")}{" "}
                </option>
                <option value={"amount"}>{t("setting:hc.spceficAmt")}</option>
              </Select>

              <TextField
                id="outlined-textarea"
                label={t("setting:hc.fillValue")}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="value"
                value={value}
                onChange={valueChange}
                size="small"
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={Loading}
              >
                {Loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {/* Add = 追加 */}
                {t("setting:hc.addFee")}
                <AddIcon className={classes.rightIcon} />
              </Button>
            </Form>
          </Formik>

          {/* <form noValidate autoComplete="off" onChange={handleChange}></form> */}
        </div>
      </div>
      <div className="col-8">
        <Card>
          {/* Category List - カテゴリーリスト */}
          <CardHeader title={t("setting:hc.list")}>
            <CardHeaderToolbar>
              {/* <button type="button" className="btn btn-primary">
            商品追加
          </button> */}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>{ShowTable && <HandlingChargesTable />}</CardBody>
        </Card>
      </div>
    </div>
  );
}
