import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import Badge from "react-bootstrap/Badge";
import { FaDownload } from "react-icons/fa";

const MySearch = (props) => {
  const { t } = useTranslation(["setting", "common"]);
  let input;
  const handleChange = () => {
    //Set data to State Here
    props.getSearchResult(input.value);
  };
  return (
    <div>
      <input
        className="form-control"
        ref={(n) => (input = n)}
        type="text"
        onChange={handleChange}
        style={{ maxWidth: "200px" }}
        placeholder={t("setting:settings.search")}
      />
    </div>
  );
};

const RemoteAll = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  cellEditProps,
}) => (
  <div>
    <BootstrapTable
      remote
      keyField="_id"
      data={data}
      columns={columns}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      cellEdit={cellEditFactory(cellEditProps)}
      onTableChange={onTableChange}
    />
  </div>
);

const RemoteTable = ({
  data,
  columns,
  onTableChange,
  page,
  sizePerPage,
  totalSize,
  getSearchResult,
  cellEditProps,
  options,
}) => (
  <div>
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columns}
      getSearchResult={getSearchResult}
      search
    >
      {(toolkitprops) => [
        <MySearch
          getSearchResult={getSearchResult}
          {...toolkitprops.searchProps}
        />,
        <BootstrapTable
          remote={{
            filter: true,
            pagination: true,
            sort: false,
            cellEdit: true,
            Search: false,
          }}
          keyField="id"
          data={data}
          columns={columns}
          onTableChange={onTableChange}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center"
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          cellEdit={cellEditFactory(cellEditProps)}
          noDataIndication={() => {
            return <div style={{ textAlign: "center" }}>{options}</div>;
          }}
        />,
      ]}
    </ToolkitProvider>
  </div>
);

export default function Table({
  columns,
  handleTableChange,
  getSearchResult,
  state,
  saveCell,
  options,
}) {
  const { t } = useTranslation(["setting", "common"]);
  const cellEditProps = {
    mode: "dbclick",
    blurToSave: true,
    beforeSaveCell: (oldValue, newValue, row, column) => {
      saveCell(oldValue, newValue, row, column);
    },
  };

  return (
    <>
      <RemoteTable
        data={state.data}
        page={state.page}
        sizePerPage={state.sizePerPage}
        totalSize={state.totalSize}
        columns={columns}
        onTableChange={handleTableChange}
        getSearchResult={getSearchResult}
        cellEditProps={cellEditProps}
        options={t("common:noData")}
      />
    </>
  );
}
