import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
// import { ShippingMgmnt } from "./view/ShippingManagement/ShippingManagement";
import { Settings } from "./view/Settings/Settings";
import { Category } from "./view/Settings/Category/Category";
import { Manufacturer } from "./view/Settings/Manufacturer/Manufacturer";
import { Supplier } from "./view/Settings/Supplier/Supplier";
import { SalesManagement } from "./view/SalesManagement/SalesManagement";
import { ShippingMethod } from "./view/Settings/ShippingMethod/ShippingMethod";
import { HandlingCharges } from "./view/Settings/HandlingCharges/HandlingCharges";
import { shallowEqual, useSelector, connect } from "react-redux";
import OrderEntry from "./modules/ECommerce/pages/orderEntry/OrderEntry";
import Shipment from "./modules/ECommerce/pages/shipment/Shipment";

// MyProducts Page

const Issues = lazy(() => import("../app/view/Issues/Issues"));
const SalesManagementWrapper = lazy(() =>
  import("../app/view/SalesManagement/SalesManagmentWrapper")
);

const SimulatorWrapper = lazy(() =>
  import("../app/view/ProfitSimulation/SimulatorWrapper")
);

const Product = lazy(() =>
  import("../app/modules/ECommerce/pages/Products/Products")
);

const NewProduct = lazy(() =>
  import("../app/modules/ECommerce/pages/Products/Index")
);
const ShippingMgmnt = lazy(() =>
  import("./view/ShippingManagement/ShippingManagement")
);

const OtzOrders = lazy(() => import("./view/OtzOrders/Otz"));
const OtzSales = lazy(() => import("./view/OtzSales/OtzSales"));

const CreateOrder = lazy(() =>
  import("../app/modules/ECommerce/pages/orderEntry/OrderEntry")
);

const OrderShipment = lazy(() => import("./view/OrderShipment/combineOrders"));
//

const ProductEdit = lazy(() =>
  import("./modules/ECommerce/pages/ProductEdit/ProductEditModal")
);

const AdvanceOrder = lazy(() =>
  import("../app/view/AdvanceOrder/AdvanceOrderWrapper")
);
const Simulator = lazy(() => import("../app/view/ProfitSimulation/Simulator"));

const ProductRequest = lazy(() => import("../app/view/RequestTable/Request"));

export default function BasePage() {
  const { isAdmin } = useSelector(
    ({ auth }) => ({
      isAdmin: auth.user === "admin",
    }),
    shallowEqual
  );

  const { isOrderCreator } = useSelector(
    ({ auth }) => ({
      isOrderCreator: auth.user === "orderCreator",
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {isOrderCreator && <Redirect exact from="/" to="/orderentryform" />}

        <ContentRoute path="/orderentryform" component={OrderEntry} />
        <ContentRoute path="/shipment" component={Shipment} />
        <Redirect exact from="/" to="/shipping-management" />
        <ContentRoute path="/products/new" component={NewProduct} />
        <ContentRoute path="/products" component={Product} />
        <ContentRoute path="/shipping-management" component={ShippingMgmnt} />
        <ContentRoute
          path="/settings/handling-charges"
          component={HandlingCharges}
        />
        <ContentRoute path="/product-edit" component={ProductEdit} />
        <ContentRoute path="/ot-sales-management" component={OtzSales} />
        <ContentRoute path="/order-amalgamation" component={OrderShipment} />
        <ContentRoute path="/settings/Manufacturer" component={Manufacturer} />
        <ContentRoute path="/settings/supplier" component={Supplier} />
        <ContentRoute path="/issues" component={Issues} />
        <ContentRoute path="/AdvanceOrder" component={AdvanceOrder} />
        <ContentRoute path="/Simulator" component={SimulatorWrapper} />
        <ContentRoute path="/newproductrequest" component={ProductRequest} />

        <ContentRoute
          path="/sales-management"
          component={SalesManagementWrapper}
        />
        <ContentRoute path="/otz" component={OtzOrders} />
        <ContentRoute
          path="/settings/shippingMethod"
          component={ShippingMethod}
        />
        <ContentRoute path="/settings/category" component={Category} />
        <ContentRoute path="/settings" component={Settings} />
      </Switch>
    </Suspense>
  );
}
