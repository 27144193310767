/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect, useRef } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import * as requestfromserver from "../../../../../app/network/api";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import Select from "react-select";
import i18n from "../../../../../i18n";
// import { Select } from "../../../../../_metronic/_partials/controls";
import {
  isEcOrder,
  isOtzOrder,
} from "../../../../../redux/actions/Notification";
import { changeLang } from "../../../../../redux/actions/langAction";
import useInterval from "use-interval";
import AmazonInd from "./icon/AmazonInd.png";
import AmazonJP from "./icon/AmazonJp.png";
import Kiwami from "./icon/kiw.png";
import Otz from "./icon/otz.png";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Link, withRouter, useHistory, Redirect } from "react-router-dom";
// import { useLang, setLanguage } from "../../../../i18n";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const customStyles = {
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Alerts");
  const { t } = useTranslation(["common", "ordermanagement"]);
  const [NotificationOrders, setNotificationOrders] = useState([]);
  const [creationTime, setcreationTime] = useState(null);
  const [NewNotification, setNewNotification] = useState(0);
  const [showSpiral, setshowSpiral] = useState(false);

  // OTZ Order ------------------------------------------------------
  const [NotificationOtzOrders, setNotificationOtzOrders] = useState([]);
  const [OtzCreationTime, setOtzCreationTime] = useState(null);
  const [NewOtzNotification, setNewOtzNotification] = useState(0);
  const [showOTZSpiral, setOTZshowSpiral] = useState(false);
  // ----------------------------------------------------------------

  // For Order Count --------------------------------------------------
  const [totalOrderCount, settotalOrderCount] = useState(0);
  //-------------------------------------------------------------

  const dispatch = useDispatch();

  // ---------------------------------------------------------------------------
  // const lang = useLang();

  useEffect(() => {
    const item = localStorage.getItem("i18nConfig");
    if (item) {
      const { selectedLang } = JSON.parse(item);
      i18n.changeLanguage(selectedLang);
      dispatch(changeLang(selectedLang));
    }
  }, []);
  // ---------------------------------------------------------------------------
  const { isOrder } = useSelector(
    ({ EcOrder }) => ({
      isOrder: EcOrder.newOrder === true,
    }),
    shallowEqual
  );

  const [languageSelected, setlanguageSelected] = useState({
    value: "jp",
    label: "JP",
  });
  const options = [
    { value: "en", label: "EN" },
    { value: "jp", label: "JN" },
  ];

  const handleChange = (change) => {
    i18n.changeLanguage(change.value);
    setlanguageSelected(change);
    if (change.value === "en") {
      dispatch(changeLang("en"));
    } else {
      dispatch(changeLang("ja"));
    }
  };
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
    requestfromserver.fetchOrders(1, 1).then((res) => {
      if (res.data.data.length) {
        setcreationTime(res.data.data[0].createdAt);
      }
    });
  }, []);

  useEffect(() => {
    requestfromserver.fetchOtzOrders(1, 1).then((res) => {
      if (res.data.data.length) {
        setOtzCreationTime(res.data.data[0].createdAt);
      }
    });
  }, []);

  // POLLING -----------------------------------------------------------------------

  const isAuthorized = useSelector((state) => state.auth.user);
  console.log("ISAUTHORIZED-----------" + isAuthorized);
  // orderCreator
  // admin

  if (isAuthorized === "admin") {
  }
  useInterval(() => {
    if (OtzCreationTime && isAuthorized === "admin") {
      requestfromserver.fetchCreationOtzOrders(OtzCreationTime).then((res) => {
        if (res.data.data.length) {
          res.data.data.map((item) => {
            if (NotificationOtzOrders.length === 0) {
              setNotificationOtzOrders([
                {
                  NotificationCount: 1,
                  orderDetails: item,
                },
              ]);
            } else {
              setNotificationOtzOrders([
                ...NotificationOtzOrders,
                {
                  NotificationCount:
                    NotificationOtzOrders[NotificationOtzOrders.length - 1]
                      .NotificationCount + 1,
                  orderDetails: item,
                },
              ]);
            }
          });
          setOtzCreationTime(res.data.data[res.data.data.length - 1].createdAt);
        }
      });
    }
  }, 10000);

  useInterval(() => {
    if (creationTime && isAuthorized === "admin") {
      requestfromserver.orderNotification(creationTime).then((res) => {
        if (res.data.data.length) {
          console.log(res.data.data);
          res.data.data.map((item) => {
            if (NotificationOrders.length === 0) {
              setNotificationOrders([
                {
                  NotificationCount: 1,
                  orderDetails: item,
                },
              ]);
            } else {
              setNotificationOrders([
                ...NotificationOrders,
                {
                  NotificationCount:
                    NotificationOrders[NotificationOrders.length - 1]
                      .NotificationCount + 1,
                  orderDetails: item,
                },
              ]);
            }
          });
          setcreationTime(res.data.data[res.data.data.length - 1].createdAt);
        }
      });
    }
  }, 10000);

  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    settotalOrderCount(
      NotificationOrders.length + NotificationOtzOrders.length
    );
  }, [NotificationOrders, NotificationOtzOrders]);

  useEffect(() => {
    if (totalOrderCount > 0) {
      setshowSpiral(true);
    }
  }, [totalOrderCount]);

  const [otzLength, setotzLength] = useState(0);
  const [ecOrderLength, setecOrderLength] = useState(0);
  useEffect(() => {
    if (NotificationOtzOrders.length !== otzLength) {
      setotzLength(NotificationOtzOrders.length);
    }
    if (NotificationOrders.length !== ecOrderLength) {
      setecOrderLength(NotificationOrders.length);
    }
  }, [NotificationOtzOrders, NotificationOrders]);

  let otzOrderCout = 0;
  let ecOrderCount = 0;

  useEffect(() => {
    if (otzLength !== otzOrderCout) {
      dispatch(isOtzOrder(true));
      otzOrderCout = otzLength;
    } else if (ecOrderLength !== ecOrderCount) {
      dispatch(isEcOrder(true));
      ecOrderCount = ecOrderLength;
    }
    // console.log(
    //   "OTZ Length : " + otzLength,
    //   "EC Order length: " + ecOrderLength
    // );
  }, [otzLength, ecOrderLength]);

  const handleNotificationClick = () => {
    setshowSpiral(false);
    setOTZshowSpiral(false);
  };

  useEffect(() => {
    console.log("ShowSpiral: " + showSpiral);
  }, [showSpiral]);

  useEffect(() => {
    console.log(layoutProps.offcanvas);
  }, [layoutProps.offcanvas]);
  let history = useHistory();
  // ------------------------------------------------------------------------------------------
  const pushOtz = () => {
    history.push("/otz");
  };

  const pushEC = () => {
    console.log("EC ran");
    history.push("/shipping-management");
  };
  // ------------------------------------------------------------------------------------------
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>

            {/* <span className="pulse-ring"></span> */}
          </div>
        </div>
      )}
      <div className="topbar-item btn-lg">
        <div style={{ width: "80px" }}>
          {/* <button onClick={pushOtz}>Clcikme</button> */}
        </div>
      </div>

      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  {t("ordermanagement:notification")}
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
                onClick={handleNotificationClick}
              >
                {showSpiral && (
                  <>
                    <span className="pulse-ring"></span>
                    <span className="pulse-ring"></span>
                  </>
                )}
                {showOTZSpiral && (
                  <>
                    <span className="pulse-ring"></span>
                    <span className="pulse-ring"></span>
                  </>
                )}
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                {/* {NotificationRead && <span className="pulse-ring" />} */}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-start rounded-top p-8 d-flex justify-content-between">
                  <span className="text-white">
                    {t("ordermanagement:notification")}
                  </span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2 py-1">
                    {totalOrderCount} {t("ordermanagement:new")}
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  ></Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        {NotificationOtzOrders.length
                          ? NotificationOtzOrders.map((item) => (
                              <div
                                className="d-flex align-items-center mb-6"
                                key={item.orderDetails.paypalTransactionId}
                              >
                                <div className="symbol symbol-40 symbol-light-primary mr-5">
                                  <span className="symbol-label">
                                    <img
                                      src={Otz}
                                      className="svg-icon-lg svg-icon-primary notification-icon"
                                      alt="icon"
                                    />
                                  </span>
                                </div>
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href="/otz"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    {t("ordermanagement:otzorder")}
                                  </a>
                                  <span className="text-muted">
                                    {`${t("ordermanagement:table.orderID")}: ${
                                      item.orderDetails.paypalTransactionId
                                    } ${moment(
                                      item.orderDetails.createdAt
                                    ).format("YYYY-MM-DD")}`}
                                  </span>
                                </div>
                              </div>
                            ))
                          : null}

                        {NotificationOrders.length
                          ? NotificationOrders.map((item) => (
                              <div
                                className="d-flex align-items-center mb-6"
                                key={item.orderDetails.orderId}
                              >
                                <div className="symbol symbol-40 symbol-light-primary mr-5">
                                  <span className="symbol-label">
                                    {item.orderDetails.platformCode ===
                                      "AMAZONIND" && (
                                      <img
                                        src={AmazonInd}
                                        className="svg-icon-lg svg-icon-primary notification-icon"
                                        alt="icon"
                                      />
                                    )}
                                    {item.orderDetails.platformCode ===
                                      "AMAZONJP" && (
                                      <img
                                        src={AmazonJP}
                                        className="svg-icon-lg svg-icon-primary notification-icon"
                                        alt="icon"
                                      />
                                    )}
                                    {item.orderDetails.platformCode ===
                                      "KIWAMI" ||
                                      (item.orderDetails.platformCode ===
                                        "SHOPIFY" && (
                                        <img
                                          src={Kiwami}
                                          className="svg-icon-lg svg-icon-primary notification-icon"
                                          alt="icon"
                                        />
                                      ))}
                                  </span>
                                </div>
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href="/shipping-management"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    {t("ordermanagement:ecOrder")}
                                  </a>
                                  <span className="text-muted">
                                    {`${t("ordermanagement:table.orderID")}: ${
                                      item.orderDetails.orderId
                                    } ${moment(
                                      item.orderDetails.createdAt
                                    ).format("YYYY-MM-DD")}`}
                                  </span>
                                </div>
                              </div>
                            ))
                          : null}
                      </PerfectScrollbar>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
                      <div className="d-flex flex-center text-center text-muted min-h-200px">
                        All caught up!
                        <br />
                        No new notifications.
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

const dateFormatter = (cell) => {
  var date = new Date(cell);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return moment(`${year}/${month}/${dt}`, "YYYY-MM-DD").format(`MMM Do YY`);
};
