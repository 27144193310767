import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import categoryReducer from "../redux/reducer/category";
import manufacturerReducer from "../redux/reducer/manufacturer";
import supplierReducer from "../redux/reducer/supplier";
import shippingMethod from "../redux/reducer/ShippingMethod";
import handlingCharges from "../redux/reducer/handlingCharges";
import order from "../redux/reducer/order";
import StatusRefresh from "./reducer/StatusRefresh";
import NotificationReducer from "./reducer/Notification";
import langReducer from "./reducer/lang";
import statusChange from "./reducer/statuschangebottom";
import otzStatusChange from "./reducer/otzstatuschange";
import sortData from "./reducer/sortData";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  category: categoryReducer,
  manufacturer: manufacturerReducer,
  supplier: supplierReducer,
  shippingMethod: shippingMethod,
  handlingCharges: handlingCharges,
  order,
  Status: StatusRefresh,
  EcOrder: NotificationReducer,
  lang: langReducer,
  statusChange: statusChange,
  otzStatusChange: otzStatusChange,
  sortData: sortData,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
