import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as action from "../../../../redux/actions/index";
import Table from "../Components/Table";
import * as requestFromServer from "../../../network/api";
import Modal from "../Components/SettingModal";
import { Type } from "react-bootstrap-table2-editor";
import { statusButtons } from "../Components/ViewButtons";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  TextField,
  IconButton,
  Button,
  fade,
} from "@material-ui/core";
import moment from "moment";

export default function SupplierTable() {
  const { t } = useTranslation(["setting"]);
  const dispatch = useDispatch();
  const [showModal, setshowModal] = useState(false);
  const [changedData, SetChangedData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, sizePerPage: 10 });

  const { currentState } = useSelector(
    (state) => ({ currentState: state.supplier }),
    shallowEqual
  );
  const { supplier, totalCount } = currentState;

  const [state, setState] = useState({
    page: 1,
    data: supplier,
    totalSize: totalCount,
    sizePerPage: 10,
  });

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, cellEdit }
  ) => {
    requestFromServer.fetchSupplier(page, sizePerPage).then((res) => {
      setPagination((prevState) => {
        return {
          ...prevState,
          page: page,
          sizePerPage: sizePerPage,
        };
      });
      let DataArray = [];
      res.data["data"].map((item) => DataArray.push(item));

      dispatch(action.createSupplier(DataArray));

      setState((prevState) => {
        return {
          ...prevState,
          page: page,
          data: DataArray,
          totalSize: totalCount,
          sizePerPage: sizePerPage,
        };
      });
    });

    if (sortField) {
      requestFromServer
        .sortSupplier(sortField, sortOrder, sizePerPage, page)
        .then((res) => {
          setPagination((prevState) => {
            return {
              ...prevState,
              page: page,
              sizePerPage: sizePerPage,
            };
          });
          let DataArray = [];
          res.data["data"].map((item) => DataArray.push(item));

          dispatch(action.createSupplier(DataArray));

          setState((prevState) => {
            return {
              ...prevState,
              page: page,
              data: DataArray,
              totalSize: totalCount,
              sizePerPage: sizePerPage,
            };
          });
        });
    }
  };

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        page: pagination.page,
        data: supplier,
        totalSize: totalCount,
        sizePerPage: pagination.sizePerPage,
      };
    });
  }, [supplier, totalCount, pagination.page, pagination.sizePerPage]);

  const getSearchResult = (query) => {
    //Need to make api call to database and set State here
    //TODO: Query Database and set result to State

    requestFromServer
      .searchSupplier(query)
      .then((res) => {
        setState((prevState) => {
          return {
            ...prevState,
            page: res.data["data"].slice(0, 10),
            data: res.data["data"],
            totalSize: 1,
            sizePerPage: res.data["totalcount"],
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const savecell = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {
      setshowModal(true);
      SetChangedData({ oldValue, newValue, row, column });
    }
  };

  const hideModal = () => {
    console.log("hideModal");
    setshowModal(false);
  };

  const onSubmit = () => {
    console.log("onSubmit");
    setshowModal(false);
    if (changedData) {
      const oldValue = changedData["oldValue"];
      const newValue = changedData["newValue"];
      const row = changedData["row"];
      const column = changedData["column"];

      if (typeof oldValue !== "boolean") {
        requestFromServer
          .updateSupplierPut(row._id, newValue, row.activeStatus)
          .then((res) => {
            const MappedArray = supplier.map(function(item) {
              return item._id == res.data["data"]._id ? res.data["data"] : item;
            });
            setState((prevState) => {
              return {
                ...prevState,
                data: MappedArray,
              };
            });
          });
      } else {
        let Bool = newValue;
        Bool === "false" ? (Bool = false) : (Bool = true);
        requestFromServer
          .updateSupplierPut(row._id, row.name, Bool)
          .then((res) => {
            const MappedArray = supplier.map(function(item) {
              return item._id == res.data["data"]._id ? res.data["data"] : item;
            });

            setState((prevState) => {
              return {
                ...prevState,
                data: MappedArray,
              };
            });
          });
      }
    }
  };

  // col---------------------------------------------------------------------------------------------------------------------
  const statusButtons = (status) => {
    if (status === "Active") {
      return (
        <Button variant="outlined" color="primary" size="small">
          {t("setting:settings.Active")}
        </Button>
      );
    } else {
      return (
        <Button variant="outlined" color="secondary" size="small">
          {t("setting:settings.InActive")}
        </Button>
      );
    }
  };
  const dateFormatter = (cell, row) => {
    if (cell) {
      var a = new Date(cell);
      return `${a.getUTCFullYear()}/${a.getUTCMonth()}/${a.getUTCDate()}`;
    }
    return cell;
  };

  const columns = [
    {
      dataField: "_id",
      text: "ID",
      hidden: true,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "name",
      // Category Name - カテゴリー名
      text: `${t("setting:supplier.supplierName")}`,
      headerAlign: "center",
      align: "center",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "createdAt",
      // Created On - 作成日
      text: `${t("setting:supplier.createdDate")}`,
      editable: false,
      formatter: (cell) => {
        if (cell) {
          return moment(cell).format("YYYY-MM-DD");
        }
      },
      headerAlign: "center",
      align: "center",
      sort: true,
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "activeStatus",
      // Status - ステータス
      text: `${t("setting:supplier.status")}`,
      sort: true,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      formatter: (cell) =>
        cell ? statusButtons("Active") : statusButtons("InActive"),
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return [
            {
              value: "true",
              label: `${t("setting:settings.Active")}`,
            },
            {
              value: "false",
              label: `${t("setting:settings.InActive")}`,
            },
          ];
        },
      },
    },
  ];
  // ---------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        showModal={showModal}
        hideModal={hideModal}
        onSubmit={onSubmit}
        field={"Supplier"}
      />
      <Table
        columns={columns}
        handleTableChange={handleTableChange}
        getSearchResult={getSearchResult}
        state={state}
        saveCell={savecell}
      />
    </>
  );
}
