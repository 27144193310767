import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector, shallowEqual } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { Orders } from "./Mock_data";
import { FaBook, FaBolt } from "react-icons/fa";
import * as requestfromserver from "../../network/api";
import CsvModal from "./CsvModal";
import { Product_Button } from "./viewButtons";
import ProductModal from "./ProductModal";
import { useSubheader } from "../../../_metronic/layout";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Html5Entities } from "html-entities";
import Paper from "@material-ui/core/Paper";
import kiwami from "./icon/kiwami.PNG";
import moment from "moment";
import AmazonInd from "./icon/AmazonInd.png";
import AmazonJp from "./icon/AmazonJp.png";
import { useTranslation } from "react-i18next";
import "moment/min/locales";
import Button from "react-bootstrap/Button";
import { Nav, Tab } from "react-bootstrap";

// Daypicker
import { DatePicker } from "react-date-picker";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const entities = new Html5Entities();

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 6px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const MySearch = (props) => {
  const { t } = useTranslation(["common", "salesmanagement"]);
  const [value, setValue] = useState([
    props.resetDate.STDATE,
    props.resetDate.ENDATE,
  ]);
  let input;
  const handleChange = () => {
    //Set data to State Here
    props.getSearchResult(input.value);
  };

  const classes = useStyles();

  const handletoFromChange = (Datevalue) => {
    console.log(Datevalue);
    let startDate = moment(Datevalue[0]).format("YYYY-MM-DD");
    let endDate = moment(Datevalue[1]).format("YYYY-MM-DD");
    setValue([startDate, endDate]);
    // props.callbackFromParent(startDate, endDate);
  };

  const submitDateRange = () => {
    props.fromChange(value[0]);
    props.toChange(value[1]);
  };
  const currentLang = useSelector((state) => state.lang);

  useEffect(() => {
    console.log(currentLang);
  }, [currentLang]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
      }}
    >
      {/* Left */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginRight: "1rem" }}>
          <input
            className="form-control"
            ref={(n) => (input = n)}
            type="text"
            onChange={handleChange}
            style={{ maxWidth: "200px" }}
            placeholder={t("salesmanagement:searchText")}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <DateRangePicker
              locale={moment.locale(currentLang.lang)}
              clearIcon={null}
              format={"y/MM/dd"}
              onChange={(d) => handletoFromChange(d)}
              value={value}
              calendarIcon={<EventNoteIcon />}
              calendarAriaLabel={"Amit"}
            />
          </div>
          <div>
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              onClick={submitDateRange}
              style={{ marginLeft: "10px" }}
            >
              {t("salesmanagement:Search")}
            </button>
          </div>
        </div>
      </div>

      {/* Right */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "32px",
        }}
      >
        <button
          type="button"
          className="btn btn-light-primary font-weight-bolder font-size-sm"
          onClick={props.csvdownload}
          style={{ marginRight: "10px" }}
        >
          {t("salesmanagement:csv_download")}
        </button>

        <FormControl className={classes.margin}>
          <NativeSelect
            id="demo-customized-select-native"
            onChange={props.platformChange}
            input={<BootstrapInput />}
          >
            <option value={"All"}>{t("common:status.ALL")}</option>
            <option value={"SHOPIFY"}>Kiwami</option>
            <option value={"AMAZONJP"}>AmazonJp</option>
            <option value={"AMAZONIND"}>AmazonInd</option>
          </NativeSelect>
        </FormControl>
      </div>
    </div>
  );
};

const RemoteTable = ({
  data,
  columns,
  onTableChange,
  page,
  sizePerPage,
  totalSize,
  getSearchResult,
  cellEditProps,
  fromChange,
  toChange,
  platformChange,
  csvdownload,
  resetDate,
  // callbackFromParent,
  options,
}) => (
  <div>
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columns}
      getSearchResult={getSearchResult}
      search
      csvdownload={csvdownload}
      resetDate={resetDate}
    >
      {(toolkitprops) => [
        <span>
          <MySearch
            getSearchResult={getSearchResult}
            fromChange={fromChange}
            toChange={toChange}
            platformChange={platformChange}
            csvdownload={csvdownload}
            resetDate={resetDate}
            // callbackFromParent={callbackFromParent}
            {...toolkitprops.searchProps}
          />
        </span>,
        <BootstrapTable
          remote={{
            filter: true,
            pagination: true,
            sort: false,
            cellEdit: true,
            Search: true,
          }}
          keyField="id"
          data={data}
          columns={columns}
          onTableChange={onTableChange}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center"
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          cellEdit={cellEditFactory(cellEditProps)}
          noDataIndication={() => {
            return <div style={{ textAlign: "center" }}>{options}</div>;
          }}
        />,
      ]}
    </ToolkitProvider>
  </div>
);

function SalesManagementTable({ ChangeSalesCardValue }) {
  const { t } = useTranslation(["common", "salesmanagement"]);
  const [state, setstate] = useState({
    page: 1,
    data: null,
    totalSize: 0,
    sizePerPage: 25,
  });

  const [productModal, setproductModal] = useState(false);
  const [productModalData, setproductModalData] = useState(null);
  const [From, setFrom] = useState(null);
  const [To, setTo] = useState(null);
  const [Platforms, setPlatforms] = useState(null);
  const [showCsvModal, setshowCsvModal] = useState(false);
  const [key, setKey] = useState("Month");
  const [resetDate, setresetDate] = useState({
    STDATE: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    ENDATE: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (key === "Day") {
      var curr = moment()
        // .subtract(1, "days")
        .format("YYYY-MM-DD");
      var tmr = moment()
        // .add(1, "days")
        .format("YYYY-MM-DD");

      ChangeSalesCardValue(curr, curr);
      setTo(null);
      setFrom(curr);
    } else if (key === "Week") {
      const today = moment();
      const from_date = today.startOf("week").format("YYYY-MM-DD");
      const to_date = today.endOf("week").format("YYYY-MM-DD");
      setFrom(from_date);
      setTo(to_date);
      // callbackFromParent(from_date, to_date);
    } else if (key === "Month") {
      var date = new Date();

      var firstDay = resetDate.STDATE;

      var lastDay = resetDate.ENDATE;

      setFrom(firstDay);
      setTo(lastDay);
      // callbackFromParent(firstDay, lastDay);
    }
  }, [key]);

  // Reset Card Data from Field
  useEffect(() => {
    // ChangeSalesCardValue(resetDate.STDATE, resetDate.ENDATE);
    if (From && To) {
      ChangeSalesCardValue(From, To);
    }
  }, [From, To]);

  // -----------------------------------------------------------

  const csvdownload = () => {
    setshowCsvModal(true);
  };

  const hideCsvModal = () => {
    setshowCsvModal(false);
  };

  // -----------------------------------------------------------

  const cellEditProps = {
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      if (oldValue !== newValue) {
      }
    },
    beforeSaveCell: (oldValue, newValue, row, column) => {},
  };

  const getSalesData = (pageNumber, sizeperpage) => {
    requestfromserver
      .ECSalesFiltersSort(
        pageNumber,
        sizeperpage,
        true,
        false,
        false,
        false,
        moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      )
      .then((res) => {
        if (res.data.data) {
          setstate((prevState) => {
            return {
              ...prevState,
              data: res.data.data,
              totalSize: res.data.totalCount,
            };
          });
        }
      });
  };
  useEffect(() => {
    getSalesData(1, 25);
  }, []);

  const resetTable = () => {
    setresetDate({
      ...resetDate,
      STDATE: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      ENDATE: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    // callbackFromParent(
    //   moment()
    //     .startOf("month")
    //     .format("YYYY-MM-DD"),
    //   moment()
    //     .endOf("month")
    //     .format("YYYY-MM-DD")
    // );
    // Reseting the Table
    setstate((prevState) => {
      return {
        ...prevState,
        data: null,
      };
    });

    getSalesData(1, 25);
  };

  const columns = [
    {
      dataField: "isBookedAdvance",
      // PREORDERED ITEM：予約商品
      text: "",
      formatter: (cellContent, row) => {
        if (cellContent) {
          return <FaBook size="1.6rem" onMouseEnter={() => cellContent} />;
        }
      },
    },
    {
      dataField: "_id",
      // PREORDERED ITEM：予約商品
      text: "Id",
      headerAlign: "center",
      align: "center",
      hidden: true,
    },
    {
      dataField: "orderDate",
      // PREORDERED ITEM：予約商品
      text: `${t("salesmanagement:table.orderDate")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        return convert(cellContent);
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "orderId",
      // SKU：最小単位
      text: `${t("salesmanagement:table.orderId")}`,
      headerAlign: "center",
      editable: false,
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "100px", textAlign: "center" };
      },

      formatter: (cellContent, row) =>
        cellContent ? <strong>{cellContent}</strong> : "-",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "platformCode",
      text: `${t("salesmanagement:table.platformCode")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        switch (cellContent) {
          case "SHOPIFY":
            return (
              <img
                src={kiwami}
                className="svg-icon-lg svg-icon-primary notification-icon"
                alt="icon"
              />
            );
          case "AMAZONJP":
            return (
              <>
                <img
                  src={AmazonJp}
                  className="svg-icon-lg svg-icon-primary notification-icon"
                  alt="icon"
                />
              </>
            );
          case "AMAZONIND":
            return (
              <img
                src={AmazonInd}
                className="svg-icon-lg svg-icon-primary notification-icon"
                alt="icon"
              />
            );
          default:
            return "Unknown";
        }
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "baseOrderPrice",
      // ORDER NUMBER：注文番号
      text: `${t("salesmanagement:table.baseOrderPrice")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "130px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        if (cellContent) {
          return entities.decode("&yen") + " " + Math.ceil(cellContent);
        } else {
          return entities.decode("&yen") + " " + 0;
        }
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "product",
      // ORDER NUMBER：注文番号
      text: `${t("salesmanagement:table.purchasePrice")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        if (cell) {
          if (cell.purchasePrice) {
            return (
              entities.decode("&yen") + " " + Math.ceil(cell.purchasePrice)
            );
          } else {
            return entities.decode("&yen") + " " + 0;
          }
        } else {
          return "-";
        }
      },
    },
    {
      dataField: "discount",
      // PRODUCT NAME：商品名
      text: "値引き",
      headerAlign: "center",
      align: "center",
      hidden: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      formatter: (cellContent, row) => {
        if (cellContent) {
          return entities.decode("&yen") + " " + Math.ceil(cellContent);
        } else {
          return entities.decode("&yen") + " " + 0;
        }
      },
    },
    {
      dataField: "expectedShippingDate",
      // ORDER NUMBER：注文番号
      text: "発送日",
      headerAlign: "center",
      align: "center",
      hidden: true,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "130px", textAlign: "center" };
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },

    {
      dataField: "shippingFee",
      // ORDER NUMBER：注文番号
      text: `${t("salesmanagement:table.shippingFee")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        if (cellContent) {
          return entities.decode("&yen") + " " + Math.ceil(cellContent);
        } else {
          return entities.decode("&yen") + " " + 0;
        }
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },

    {
      dataField: "commission",
      // ORDER NUMBER：注文番号
      text: `${t("salesmanagement:table.commission")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        if (cellContent) {
          return entities.decode("&yen") + " " + Math.ceil(cellContent);
        } else {
          return entities.decode("&yen") + " " + 0;
        }
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "Product",
      // ORDER NUMBER：注文番号
      text: `${t("salesmanagement:table.product")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: Product_Button,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // setClickedId(row._id);
          if (row.product) {
            setproductModal(true);
            setproductModalData(row.product);
          }
        },
      },
    },
    {
      dataField: "Profit",
      // ORDER NUMBER：注文番号
      text: `${t("salesmanagement:table.profit")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        console.log(row);
        if (row.product) {
          if (row.product.purchasePrice) {
            const {
              baseOrderPrice,
              commission,
              closingFee,
              gst,
              shippingFee,
              customFee,
              discount,
            } = row;
            const profit =
              baseOrderPrice -
              (commission +
                closingFee +
                gst +
                shippingFee +
                customFee +
                discount) -
              row.product.purchasePrice;
            return entities.decode("&yen") + " " + Math.ceil(profit); //
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
  ];
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    //Handle Pagination
    // From TO Platforms Sort
    if (sortField) {
      // From
      if (From && To && Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            Platforms,
            sortField,
            sortOrder,
            From,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (From && !To && Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            Platforms,
            sortField,
            sortOrder,
            From,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (From && To && !Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            false,
            sortField,
            sortOrder,
            From,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }

      // To
      if (!From && To && Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            Platforms,
            sortField,
            sortOrder,
            false,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }

      // Platforms

      if (From) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            false,
            sortField,
            sortOrder,
            From,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (To) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            false,
            sortField,
            sortOrder,
            false,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            false,
            Platforms,
            sortField,
            sortOrder,
            false,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }

      if (!From && !To && !Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            false,
            false,
            sortField,
            sortOrder,
            false,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
    } else {
      // From
      if (From && To && Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            Platforms,
            false,
            false,
            From,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (From && !To && Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            Platforms,
            false,
            false,
            From,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (From && To && !Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            false,
            false,
            false,
            From,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }

      // To
      if (!From && To && Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            Platforms,
            false,
            false,
            false,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }

      // Platforms

      if (From) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            false,
            false,
            false,
            From,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (To) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            true,
            false,
            false,
            false,
            false,
            To
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }
      if (Platforms) {
        requestfromserver
          .ECSalesFiltersSort(
            page,
            sizePerPage,
            false,
            Platforms,
            false,
            false,
            false,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  page,
                  sizePerPage,
                };
              });
            }
          });
      }

      if (!From && !To && !Platforms) {
        getSalesData(page, sizePerPage);
      }
    }
  };

  useEffect(() => {
    if (From) {
      if (Platforms !== "All" && To) {
        console.log("Available: From Platform  TO");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, Platforms, false, false, From, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (To) {
        console.log("Available: From  TO");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, false, false, false, From, To)
          .then((res) => {
            if (res.data.data) {
              console.log(res.data);
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (Platforms !== "All") {
        console.log("Available: Platform  From");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, Platforms, false, false, From, false)
          .then((res) => {
            if (res.data.data) {
              console.log(res.data);
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else {
        console.log("Available: From");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, false, false, false, From, false)
          .then((res) => {
            console.log(res.data);
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      }
    }
  }, [From]);

  useEffect(() => {
    if (To) {
      if (Platforms !== "All" && From) {
        console.log("Platform TO From");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, Platforms, false, false, From, To)
          .then((res) => {
            if (res.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (From) {
        console.log("TO From");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, false, false, false, From, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (Platforms !== "All") {
        console.log("Platform TO ");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, Platforms, false, false, false, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else {
        console.log("TO ");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, false, false, false, From, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      }
    }
  }, [To]);
  useEffect(() => {
    if (Platforms !== "All") {
      if (From && To) {
        console.log("Available: Platform From TO");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, Platforms, false, false, From, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (From) {
        console.log("Available: Platform From");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, Platforms, false, false, From, false)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (To) {
        console.log("Available: Platform  TO");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, Platforms, false, false, false, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else {
        console.log("Available: Platform ");
        requestfromserver
          .ECSalesFiltersSort(
            1,
            25,
            false,
            Platforms,
            false,
            false,
            false,
            false
          )
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      }
    } else if (Platforms === "All") {
      console.log("Platforms");
      if (From && To) {
        console.log("Available: From TO");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, false, false, false, From, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (From) {
        console.log("Available: From");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, false, false, false, false, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else if (To) {
        console.log("Available:  TO");
        requestfromserver
          .ECSalesFiltersSort(1, 25, true, false, false, false, false, To)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      } else {
        requestfromserver
          .ECSalesFiltersSort(1, 25, false, false, false, false, false, false)
          .then((res) => {
            if (res.data.data) {
              setstate((prevState) => {
                return {
                  ...prevState,
                  data: res.data.data,
                  totalSize: res.data.totalCount,
                };
              });
            }
          });
      }
    }
  }, [Platforms]);

  const handlePlatformChange = (e) => {
    if (e.target.value === "All") {
      setPlatforms("All");
    } else {
      setPlatforms(e.target.value);
    }
  };

  const handleFromChange = (e) => {
    setFrom(e);
    // console.log(e);
  };

  const handleToChange = (e) => {
    setTo(e);
    // console.log(e);
  };

  const SearchByOrderId = (values) => {
    if (values.length > 0) {
      requestfromserver.searchOrders(1, 50, values).then((res) => {
        if (res.data.data) {
          const FilteredData = res.data.data.filter(
            (item) => item.shipmentStatus === "COMPLETED"
          );
          if (res.data.data) {
            console.log(res.data.data);
            setstate((prevState) => {
              return {
                ...prevState,
                data: FilteredData,
                page: 1,
              };
            });
          }
        }
      });
    } else if (values.length == 0) {
      getSalesData(1, 25);
    }
  };

  return (
    <>
      <CardHeader
        title={t("salesmanagement:salesMangement")}
        style={{ margin: "20px" }}
      >
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-danger font-weight-bolder font-size-sm"
            style={{ marginRight: "30px" }}
            onClick={resetTable}
          >
            <i className="fa fa-sync-alt"></i>
            {t("salesmanagement:reset")}
          </button>
          <EventNoteIcon fontSize="large" />

          <CardHeaderToolbar style={{ marginLeft: "30px" }}>
            <div>
              <Tab.Container defaultActiveKey={key}>
                <Nav
                  as="ul"
                  onSelect={(_key) => setKey(_key)}
                  className="nav nav-pills nav-pills-sm nav-primary-15"
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      eventKey="Month"
                      className={`nav-link py-2 px-4 ${
                        key === "Month" ? "active" : ""
                      }`}
                    >
                      {t("salesmanagement:month")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      eventKey="Week"
                      className={`nav-link py-2 px-4 ${
                        key === "Week" ? "active" : ""
                      }`}
                    >
                      {t("salesmanagement:week")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      eventKey="Day"
                      className={`nav-link py-2 px-4 ${
                        key === "Day" ? "active" : ""
                      }`}
                    >
                      {t("salesmanagement:today")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
          </CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <>
        <CardBody>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            style={{ marginBottom: "10px" }}
          >
            <Grid item>
              <Grid container spacing={2}>
                <Grid item></Grid>
              </Grid>
            </Grid>
          </Grid>
          {showCsvModal && (
            <CsvModal showModal={showCsvModal} hideModal={hideCsvModal} />
          )}
          {productModal && productModalData && (
            <ProductModal
              showModal={productModal}
              hideModal={() => setproductModal(false)}
              data={productModalData}
            />
          )}
          {state.data !== null && (
            <>
              <RemoteTable
                data={state.data}
                page={state.page}
                sizePerPage={state.sizePerPage}
                totalSize={state.totalSize}
                columns={columns}
                onTableChange={handleTableChange}
                cellEditProps={cellEditProps}
                getSearchResult={SearchByOrderId}
                fromChange={handleFromChange}
                toChange={handleToChange}
                platformChange={handlePlatformChange}
                csvdownload={csvdownload}
                resetDate={resetDate}
                // callbackFromParent={callbackFromParent}
                options={t("common:noData")}
              />
            </>
          )}
        </CardBody>
      </>
    </>
  );
}
export default SalesManagementTable;

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function startOfWeek(date) {
  var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

  return new Date(date.setDate(diff));
}
