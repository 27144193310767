import { stubFalse } from "lodash";
import * as actionType from "../actions/actionsType";

const initialState = {
  status: "RECEIVED",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CHANGE_OTZ_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
