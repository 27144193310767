import React, { useState, useEffect } from "react";

import {
  makeStyles,
  TextField,
  IconButton,
  Button,
  fade,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import ShipmentTable from "./ShipmentTable";
import { NavLink, Route } from "react-router-dom";
// import { MyForm } from "./MyForm";
import { validateUser } from "../../../Auth/_redux/authCrud";
import { Redirect } from "react-router-dom";
import { set } from "object-path";
import { useHistory } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Shipment = () => {
  const { t } = useTranslation(["common", "shipment"]);
  return (
    <React.Fragment>
      <div className="col-12">
        {/* {status ? <Redirect from="/products" to="/auth/login" /> : null} */}
        <Card>
          {/* <CardHeader title="Shipment"></CardHeader> */}
          <CardHeader title={t("common:shipments")}>
            <CardHeaderToolbar></CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <ShipmentTable />
            {/* <MyForm /> */}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Shipment;
