import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
  Input,
  DatePickerField,
  Checkbox,
} from "../../../../../_metronic/_partials/controls";
import AsyncSelect from "react-select/async";
import Select, { components } from "react-select";
import { FormikTextField } from "formik-material-fields";
import { Formik, Form, Field, FieldArray, getIn, ErrorMessage } from "formik";
import * as Yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Divider } from "@material-ui/core";
import { SearchProductTable, createOrder } from "../Products/apicall";
import Button from "react-bootstrap/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FaEnvelope, FaPhone, FaTimes } from "react-icons/fa";
import moment from "moment";
import CustomErrorMessage from "./ErrorMessage";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Tooltip from "@atlaskit/tooltip";
const styles = {
  root: {
    marginLeft: 5,
  },
};
const SpinnerAdornment = withStyles(styles)((props) => (
  <CircularProgress className={props.classes.spinner} size={20} />
));

const options = [
  // { value: "AMAZONJP", label: "AMAZONJP" },
  { value: "AMAZONIND", label: "AMAZONIND" },
  { value: "SHOPIFY", label: "KIWAMI" },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const msgStyles = {
  background: "#ebebeb",
  color: "white",
};

function OrderEntry() {
  const [state, setstate] = useState([]);
  const [currency, setCurrency] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [snackBar, setSnackBar] = useState({
    data: null,
    visible: false,
  });
  const [snackBarSuccess, setSnackBarSuccess] = useState(false);
  const mapOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  };
  const { t } = useTranslation(["common", "orderentry"]);

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {t("common:noOp")}
      </components.NoOptionsMessage>
    );
  };

  const handleECchange = () => {
    setCurrency(true);
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length > 1) {
      SearchProductTable(inputValue).then((res) => {
        if (res.data.data) {
          setstate(state.concat(res.data.data));
          const Name = res.data.data.map((item) => {
            const id = item._id;
            const name = item.name;
            return { id, name };
          });
          callback(mapOptionsToValues(Name));
        }
      });
    }
  };

  const AddOrderSchema = Yup.object().shape({
    orderId: Yup.string()
      // .matches(
      //   /^[0-9]+(-[0-9]+)+$/,
      //   `${t("orderentry:errors.not_correct_format")}`
      // )
      .required(
        `${t("orderentry:order_id")}` + `${t("orderentry:errors.is_required")}`
      ),
    platformCode: Yup.string().required(
      `${t("orderentry:platform")}` + `${t("orderentry:errors.is_required")}`
    ),
    products: Yup.array()
      .of(
        Yup.object().shape({
          product: Yup.string().required(
            `${t("orderentry:product")}` +
              `${t("orderentry:errors.is_required")}`
          ),
          baseOrderPrice: Yup.number().required(
            `${t("orderentry:price")}` + `${t("orderentry:errors.is_required")}`
          ),
          discount: Yup.number()
            .typeError(t("orderentry:errors.enter_valid_number"))
            .required(
              `${t("orderentry:discount")}` +
                `${t("orderentry:errors.is_required")}`
            ),
          // ShippingFee: Yup.number()
          //   .typeError("Please Enter a number")
          //   .required("Shipping Fee is required"),
          // handlingFee: Yup.number()
          //   .typeError("Please Enter a number")
          //   .required("Handling Fee is requuired"),
          orderQuantity: Yup.number()
            .typeError(t("orderentry:errors.invalid_qty"))
            .required(
              `${t("orderentry:order_quantity")}` +
                `${t("orderentry:errors.is_required")}`
            ),
        })
      )
      .required("The Product Field is required"),

    // customerInfo: Yup.object().shape({
    //   name: Yup.string().required("Name is required"),
    //   email: Yup.string().required("Email is required"),
    //   phone: Yup.number().required("Phone is required"),
    //   address: Yup.string().required("Address is required"),
    //   city: Yup.string().required("City is required"),
    //   state: Yup.string().required("State is required"),
    //   country: Yup.string().required("Country is required"),
    //   postcode: Yup.string().required("Postcode is required"),
    // }),
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarSuccess(false);
    setSnackBar((prevstate) => {
      return {
        ...snackBar,
        visible: false,
        data: null,
      };
    });
  };

  const handleOrderSubmission = (values) => {
    return new Promise((resolve, reject) => {
      const stateArray = getUniqueListBy(state, "_id");
      const productArrays = [];
      values.products.map((item) => {
        let foundProducts = stateArray.filter(
          (stateProduct) => stateProduct._id === item.product.value
        );
        productArrays.push({
          product: foundProducts.pop(),
          platformCode: values.platformCode.value,
          baseOrderPrice: item.baseOrderPrice,
          discount: item.discount,
          currency: getCurrency(values.platformCode.value),
          orderId: values.orderId,
          orderQuantity: item.orderQuantity,
          shipmentStatus: "RECEIVED",
          orderDate: moment().format("YYYY-MM-DD"),
        });
      });
      setLoader(true);
      axios
        .all(productArrays.map((item) => createOrder(item)))
        .then((responses) => {
          resolve();
          setSnackBarSuccess(true);
          setLoader(false);
        })
        .catch((err) => {
          if (err) {
            reject();
            setLoader(false);
            setSnackBar((prevstate) => {
              return {
                ...snackBar,
                visible: true,
                data: err.response.data.message,
              };
            });
          }
        });
    });
  };

  return (
    <Card>
      <CardHeader title={t("orderentry:order_entry")}>
        <CardHeaderToolbar>
          {/* <button type="button" className="btn btn-light">
            Reset
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* Formik */}
        {snackBar.data && (
          <Snackbar
            open={snackBar.visible}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="warning">
              {snackBar.data}
            </Alert>
          </Snackbar>
        )}

        <Snackbar
          open={snackBarSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {t("orderentry:order_added")}
          </Alert>
        </Snackbar>

        <Formik
          enableReinitialize={true}
          validationSchema={AddOrderSchema}
          onSubmit={(values, { resetForm }) => {
            handleOrderSubmission(values).then((res) => {
              resetForm();
            });
          }}
          initialValues={{
            orderId: "",
            platformCode: "",
            products: [
              {
                product: "",
                baseOrderPrice: "",
                discount: "",
                // ShippingFee: "",
                // handlingFee: "",
                orderQuantity: "",
              },
            ],
            // customerInfo: {
            //   name: "",
            //   email: "",
            //   phone: "",
            //   address: "",
            //   city: "",
            //   state: "",
            //   country: "",
            //   postcode: "",
            // },
          }}
        >
          {({
            errors,
            touched,
            props,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="form form-label-right">
              <div className="form-group row">
                {/* Order ID,platformCode,Product */}
                <div className="col-lg-4">
                  <Field
                    type="string"
                    name="orderId"
                    component={Input}
                    // placeholder="Order Id"
                    label={t("orderentry:order_id")}
                  />
                </div>
                <div className="col-lg-4">
                  <label htmlFor="selectBox" className="select-box__label">
                    {t("orderentry:platform")}
                  </label>
                  <div>
                    <Select
                      name="platformCode"
                      value={values.platformCode}
                      onChange={(opt, e) => {
                        setFieldValue("platformCode", opt);
                        handleECchange();
                      }}
                      onBlur={(opt, e) => {
                        setFieldTouched("platformCode", opt);
                      }}
                      options={options}
                      placeholder={t("orderentry:select")}
                    />
                    <ErrorMessage name="platformCode">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-lg-4">
                  {currency && (
                    <Field
                      type="string"
                      name="Currency"
                      component={Input}
                      placeholder="INR"
                      value="INR"
                      disabled
                      label={t("orderentry:currency")}
                    />
                  )}
                </div>
              </div>
              {/* Products Array */}
              <FieldArray
                name="products"
                render={({ insert, remove, push }) => (
                  <>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div>
                        <Button
                          type="button"
                          className="secondary"
                          onClick={() =>
                            push({
                              product: "",
                              baseOrderPrice: "",
                              discount: "",
                              ShippingFee: "",
                              handlingFee: "",
                              orderQuantity: "",
                            })
                          }
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          {t("orderentry:add_product")}
                        </Button>
                      </div>
                    </div>
                    {values.products.length > 0 &&
                      values.products.map((product, index) => (
                        <>
                          <div key={index}>
                            <div className="form-group row">
                              <div
                                className="col-lg-3"
                                style={{ alignItems: "center" }}
                              >
                                <div style={{ marginTop: "25px" }}>
                                  <AsyncSelect
                                    components={{ NoOptionsMessage }}
                                    name={`products.[${index}].product`}
                                    placeholder={t("orderentry:product")}
                                    loadOptions={loadOptions}
                                    // value={values.product}
                                    onChange={(opt, e) => {
                                      setFieldValue(
                                        `products.[${index}].product`,
                                        opt
                                      );
                                    }}
                                    onBlur={setFieldTouched}
                                    error={`errors.products.[${index}].product`}
                                    touched={`touched.products.[${index}].product`}
                                    // defaultOptions
                                    // onInputChange={this.handleInputChange}
                                  />
                                  <ErrorMessage
                                    name={`products.[${index}].product`}
                                  >
                                    {(msg) => (
                                      <div style={{ color: "red" }}>{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <Field
                                  type="number"
                                  name={`products.[${index}].baseOrderPrice`}
                                  component={Input}
                                  placeholder={t("orderentry:price")}
                                  style={{ marginTop: "25px" }}
                                />
                                {errors.products &&
                                  errors.products.length > 0 &&
                                  errors.products[index] &&
                                  errors.products[index].baseOrderPrice &&
                                  touched.products &&
                                  touched.products.length > 0 &&
                                  touched.products[index] &&
                                  touched.products[index].baseOrderPrice && (
                                    <div
                                      className="field-error"
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {errors.products[index].baseOrderPrice}
                                    </div>
                                  )}
                              </div>
                              <div className="col-lg-2">
                                <Field
                                  type="number"
                                  name={`products.[${index}].discount`}
                                  component={Input}
                                  placeholder={t("orderentry:discount")}
                                  style={{ marginTop: "25px" }}
                                />
                                {errors.products &&
                                  errors.products.length > 0 &&
                                  errors.products[index] &&
                                  errors.products[index].discount &&
                                  touched.products &&
                                  touched.products.length > 0 &&
                                  touched.products[index] &&
                                  touched.products[index].discount && (
                                    <div
                                      className="field-error"
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {errors.products[index].discount}
                                    </div>
                                  )}
                              </div>
                              <div className="col-lg-2">
                                <Field
                                  type="number"
                                  name={`products.[${index}].orderQuantity`}
                                  component={Input}
                                  // placeholder="Order Id"
                                  placeholder={t("orderentry:order_quantity")}
                                  style={{ marginTop: "25px" }}
                                />

                                {errors.products &&
                                  errors.products.length > 0 &&
                                  errors.products[index] &&
                                  errors.products[index].orderQuantity &&
                                  touched.products &&
                                  touched.products.length > 0 &&
                                  touched.products[index] &&
                                  touched.products[index].orderQuantity && (
                                    <div
                                      className="field-error"
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {errors.products[index].orderQuantity}
                                    </div>
                                  )}
                              </div>
                              <div className="col-lg-3">
                                <div className="col-lg-3">
                                  <Button
                                    type="button"
                                    className="secondary"
                                    style={{
                                      marginTop: "25px",
                                    }}
                                    onClick={() => remove(index)}
                                  >
                                    <FaTimes />
                                  </Button>
                                </div>
                              </div>

                              {/* product*/}
                            </div>
                            <div className="form-group row">
                              <div className="col-lg-3">
                                {/* <Field
                                  type="number"
                                  name={`products.[${index}].ShippingFee`}
                                  component={Input}
                                  // placeholder="Order Id"
                                  placeholder="Shipping Fees"
                                  style={{ marginTop: "25px" }}
                                />
                                {errors.products &&
                                  errors.products.length > 0 &&
                                  errors.products[index] &&
                                  errors.products[index].ShippingFee &&
                                  touched.products &&
                                  touched.products.length > 0 &&
                                  touched.products[index] &&
                                  touched.products[index].ShippingFee && (
                                    <div
                                      className="field-error"
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {errors.products[index].ShippingFee}
                                    </div>
                                  )} */}
                              </div>
                              <div className="col-lg-3">
                                {/* <Field
                                  type="number"
                                  name={`products.[${index}].handlingFee`}
                                  component={Input}
                                  // placeholder="Order Id"
                                  placeholder="Handling Fees"
                                  style={{ marginTop: "25px" }}
                                />
                                {errors.products &&
                                  errors.products.length > 0 &&
                                  errors.products[index] &&
                                  errors.products[index].handlingFee &&
                                  touched.products &&
                                  touched.products.length > 0 &&
                                  touched.products[index] &&
                                  touched.products[index].handlingFee && (
                                    <div
                                      className="field-error"
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {errors.products[index].handlingFee}
                                    </div>
                                  )} */}
                              </div>
                              {/* <div className="col-lg-3">
                                <Field
                                  type="number"
                                  name={`products.[${index}].orderQuantity`}
                                  component={Input}
                                  // placeholder="Order Id"
                                  placeholder="Order Quantity"
                                  style={{ marginTop: "25px" }}
                                />
                                {errors.products &&
                                  errors.products.length > 0 &&
                                  errors.products[index] &&
                                  errors.products[index].orderQuantity &&
                                  touched.products &&
                                  touched.products.length > 0 &&
                                  touched.products[index] &&
                                  touched.products[index].orderQuantity && (
                                    <div
                                      className="field-error"
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {errors.products[index].orderQuantity}
                                    </div>
                                  )}
                              </div> */}
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                )}
              />
              {/* Products Array */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                }}
              >
                <div>
                  <Button type="submit">
                    {t("orderentry:submit")}
                    {Loader && <SpinnerAdornment />}
                  </Button>
                </div>
              </div>
              {/* 2ND ROW */}
              {/* <div className="form-group row" style={{ marginTop: "50px" }}>
                <div className="col-lg-12">
                  <h4>
                  顧客情報
                    <Divider style={{ marginTop: "5px" }} />
                  </h4>
                </div>
              </div> */}
              {/* <div className="form-group row"> */}
              {/* CustomerInfo  */}
              {/* name,email,phone */}
              {/* orderedAt */}
              {/* <div className="col-lg-4" style={{ marginTop: "10px" }}> */}
              {/* <Field
                    type="string"
                    name="customerInfo.name"
                    component={Input}
                    // placeholder="Order Id"
                    label="Name"
                  /> */}
              {/* <FormikTextField
                    type="string"
                    name="customerInfo.name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Name"
                  /> */}
              {/* <ErrorMessage name="customerInfo.name">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage> */}
              {/* </div> */}
              {/* <div className="col-lg-4" style={{ marginTop: "10px" }}> */}
              {/* <Field
                    type="string"
                    name="customerInfo.email"
                    component={Input}
                    // placeholder="Order Id"
                    label="Email"
                  /> */}
              {/* <FormikTextField
                    type="string"
                    name="customerInfo.email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <FaEnvelope />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
              {/* <ErrorMessage name="customerInfo.email">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage> */}
              {/* </div> */}
              {/* <div className="col-lg-4" style={{ marginTop: "10px" }}> */}
              {/* <Field
                    type="string"
                    name="customerInfo.phone"
                    component={Input}
                    // placeholder="Order Id"
                    label="Phone"
                  /> */}
              {/* <FormikTextField
                    type="number"
                    name="customerInfo.phone"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Phone"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <FaPhone />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
              {/* </div>
              </div> */}
              {/* <div className="form-group row"> */}
              {/* address,city,state,  */}
              {/* <div className="col-lg-4"> */}
              {/* <Field
                    type="string"
                    name="customerInfo.address"
                    component={Input}
                    // placeholder="Order Id"
                    label="Address"
                  /> */}
              {/* <FormikTextField
                    type="string"
                    name="customerInfo.address"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Address"
                  /> */}
              {/* </div> */}
              {/* <div className="col-lg-4"> */}
              {/* <Field
                    type="string"
                    name="customerInfo.city"
                    component={Input}
                    // placeholder="Order Id"
                    label="City"
                  /> */}

              {/* <FormikTextField
                    type="string"
                    name="customerInfo.city"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="City"
                  /> */}
              {/* </div> */}
              {/* <div className="col-lg-4"> */}
              {/* <Field
                    type="string"
                    name="customerInfo.state"
                    component={Input}
                    // placeholder="Order Id"
                    label="State"
                  /> */}
              {/* <FormikTextField
                    type="string"
                    name="customerInfo.state"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="State"
                  /> */}
              {/* </div> */}
              {/* </div> */}

              {/* <div className="form-group row"> */}
              {/* country,postcode */}
              {/* <div className="col-lg-4"> */}
              {/* <Field
                    type="string"
                    name="customerInfo.country"
                    component={Input}
                    // placeholder="Order Id"
                    label="Country"
                  /> */}
              {/* <FormikTextField
                    type="string"
                    name="customerInfo.country"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Country"
                  /> */}
              {/* </div> */}
              {/* <div className="col-lg-4"> */}
              {/* <Field
                    type="string"
                    name="customerInfo.postcode"
                    component={Input}
                    // placeholder="Order Id"
                    label="PostCode"
                  /> */}
              {/* <FormikTextField
                    type="string"
                    name="customerInfo.postcode"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Postcode"
                  /> */}
              {/* </div> */}
              {/* </div> */}
            </Form>
          )}
        </Formik>

        {/* Formik End */}
      </CardBody>
    </Card>
  );
}

export default OrderEntry;

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

function getCurrency(platform) {
  if (platform === "AMAZONJP") {
    return "JPY";
  } else if (
    platform === "KIWAMI" ||
    platform === "AMAZONIND" ||
    platform === "SHOPIFY"
  ) {
    return "INR";
  }
}
