import * as actionsType from "./actionsType";

export const isEcOrder = (notification) => {
  return {
    type: actionsType.NEW_EC_ORDER,
    payload: notification,
  };
};

export const isOtzOrder = (order) => {
  return {
    type: actionsType.NEW_OTZ_ORDER,
    payload: order,
  };
};
