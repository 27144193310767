import React from "react";
import { NavLink, Route } from "react-router-dom";
import { Routes } from "../../Routes";
import { Category } from "./Category/Category";
import {
  FaTools,
  FaBox,
  FaHardHat,
  FaUser,
  FaDolly,
  FaShippingFast,
  FaWarehouse,
  FaDumpsterFire,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

export function Settings() {
  const { t } = useTranslation(["setting"]);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        className="settings"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="col">
          <div className="row">
            <div className="col">
              <div className="setting-card">
                <NavLink to="/settings/category">
                  <div className="setting-child">
                    <FaBox />
                    <div>
                      {/* Category:カテゴリー */}
                      <h5>{t("setting:settings.category")}</h5>
                      {/* manage product categories:商品カテゴリ管理 */}
                      <p>{t("setting:settings.categoryMgmt")}</p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="col">
              <div className="setting-card">
                <NavLink to="/settings/manufacturer">
                  <div className="setting-child">
                    <FaDumpsterFire />
                    <div>
                      {/* Manufacturer:製造者 */}
                      <h5>{t("setting:settings.manufaturer")}</h5>
                      {/*  manage product categories:商品カテゴリ管理  */}
                      <p>{t("setting:settings.manufacturerMgmt")}</p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col">
              <div className="setting-card">
                <div>
                  <NavLink to="/settings/handling-charges">
                    <div className="setting-child">
                      <FaDolly />
                      <div>
                        {/* Handling Charges:手数料 */}
                        <h5>{t("setting:settings.handlingcharge")}</h5>
                        {/* manage product categories:商品カテゴリ管理 */}
                        <p>{t("setting:settings.hcMgmt")} </p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="setting-card">
                <NavLink to="/settings/supplier">
                  <div className="setting-child">
                    <FaWarehouse />
                    <div>
                      {/* Supplier:供給者 */}
                      <h5>{t("setting:settings.supplier")}</h5>
                      {/*  manage product categories:商品カテゴリ管理  */}
                      <p>{t("setting:settings.supplierMgmt")}</p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="col">
              <div className="setting-card">
                <NavLink to="/settings/shippingmethod">
                  <div className="setting-child">
                    <FaShippingFast />
                    <div>
                      {/* Shipping Methods:発送方法 */}
                      <h5>{t("setting:settings.shippingMethod")}</h5>
                      {/* manage product categories:商品カテゴリ管理 */}
                      <p>{t("setting:settings.smMgmt")}</p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>

        <Route path="/settings/category" component={Category} />
      </div>
    </div>
  );
}
