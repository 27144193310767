import * as actionType from "../actions/actionsType";
import { compare } from "../../app/view/Settings/Components/Utils";

const initialState = {
  manufacturer: [],
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_MANUFACTURER:
      return {
        ...state,
        manufacturer: action.payload.sort(compare).reverse(),
      };
    case actionType.INIT_MANUFACTURER:
      return {
        ...state,
        manufacturer: state.manufacturer
          .concat(action.payload)
          .sort(compare)
          .reverse(),
      };
    case actionType.UPADTE_MANUFACTURER:
      return {
        ...state,
        manufacturer: state.manufacturer
          .concat(action.payload)
          .sort(compare)
          .reverse(),
      };

    case actionType.UPADTE_MANUFACTURER_CELL:
      return {
        ...state,
        category: action.payload,
      };
    case actionType.TOTALMANUFACTURERCOUNT:
      return {
        ...state,
        totalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
