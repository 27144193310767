import React, { useState, useEffect } from "react";
import { makeStyles, TextField, IconButton, Button } from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import * as action from "../../../../redux/actions/index";
import { Formik, Form } from "formik";
import * as requestFromServer from "../../../network/api";
import { shallowEqual } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import ShippingMethodTable from "./ShippingMethodTable";
import { useTranslation } from "react-i18next";

export function ShippingMethod() {
  const { t } = useTranslation(["setting"]);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.shippingMethod }),
    shallowEqual
  );

  const [Loading, setLoading] = useState(false);

  const [cat, setCat] = useState({
    name: "",
  });
  const dispatch = useDispatch();
  const onCatSubmit = (name) => dispatch(action.initshippingMethod(name));
  const onFetched = (payload) => dispatch(action.initshippingMethod(payload));

  useEffect(() => {
    if (currentState.shippingMethod.length == 0) {
      requestFromServer.fetchshippingMethod(1, 10).then((res) => {
        requestFromServer.fetchshippingCount().then((response) => {
          dispatch(action.setTotalShippingCount(response.data.totalCount));
        });
        if (res.data.data.length) {
          let resArray = [];
          res.data.data.map((item) => {
            resArray.push(item);
          });
          dispatch(action.createshippingMethod(resArray));
        }
      });
    }
    return () => {};
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (cat.name) {
      setLoading(true);
      requestFromServer.saveshippingMethod(cat.name).then((res) => {
        dispatch(action.initshippingMethod(res.data.data));

        requestFromServer.fetchshippingCount().then((response) => {
          dispatch(action.setTotalShippingCount(response.data.totalCount));
          setLoading(false);
          setCat({
            ...cat,
            name: "",
          });
        });
      });
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.value !== " ") {
      setCat({
        ...cat,
        name: event.target.value,
      });
    }
  };

  const useStyles = makeStyles((theme) => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
  }));

  const classes = useStyles();
  return (
    <div className="cat-wrapper row">
      <div className="col-4">
        <div className="cat-header">
          {/* Add a Category */}
          {/* <h3>カテゴリーの追加</h3> */}
          <h3>{t("setting:shippingMethod.addShippingMethod")}</h3>
        </div>
        <div className="cat-form">
          <Formik>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                id="outlined-textarea"
                label={t("setting:shippingMethod.enterShippingMethod")}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                value={cat.name}
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={Loading}
              >
                {Loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {/* Add = 追加 */}
                {t("setting:shippingMethod.add")}
                <AddIcon className={classes.rightIcon} />
              </Button>
            </Form>
          </Formik>
          {/* <form noValidate autoComplete="off" onChange={handleChange}></form> */}
        </div>
      </div>
      <div className="col-8">
        <Card>
          {/* Category List - カテゴリーリスト */}
          <CardHeader title={t("setting:shippingMethod.shippingList")}>
            <CardHeaderToolbar>
              {/* <button type="button" className="btn btn-primary">
                商品追加
              </button> */}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <ShippingMethodTable />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
