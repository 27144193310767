import * as actionType from "../actions/actionsType";

const initialState = {
  handlingcharges: [],
  totalCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_HANDLING_CHARGES:
      const sortedMap = action.payload.map((item) => {
        item.value = item.value.toString();
        return item;
      });
      return {
        ...state,
        handlingcharges: sortedMap,
      };
    case actionType.INIT_HANDLING_CHARGES:
      action.payload.value = action.payload.value.toString();
      return {
        ...state,
        handlingcharges: state.handlingcharges.concat(action.payload),
      };
    case actionType.TOTALHANDLINGCHARGESCOUNT:
      return {
        ...state,
        totalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
