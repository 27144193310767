import React, { useState, useEffect, useRef } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import ShipmentModal from "./ShipmentModal";
import OrderModal from "./OrderModal";
import { useSelector, shallowEqual } from "react-redux";
import { Type } from "react-bootstrap-table2-editor";
import ConfirmationModal from "./ConfirmationModal";
import { FaBook, FaEdit } from "react-icons/fa";
import { FaTelegramPlane, FaCartArrowDown } from "react-icons/fa";
import _, { isNull } from "lodash";
import { Button, Tooltip, MenuItem, Menu, TextField } from "@material-ui/core";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "react-switch";
import "../../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table-next";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { Html5Entities } from "html-entities";
import ToggleButton from "@material-ui/lab/ToggleButton";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { getData, updateShipmentRow, searchShippingId } from "./apicall";
import { useTranslation } from "react-i18next";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {
  Product_Button,
  Track_Button,
  View_Custom_Button,
  Add_Custom_Button,
} from "./viewButtons";

const entities = new Html5Entities();

const dateFormatter = (cell, row) => {
  if (cell) {
    var date = new Date(cell);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${year}/${month}/${dt}`;
  }
  return cell;
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "10px 12px 10px 12px",
    height: 16,

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const MySearch = (props) => {
  const [SearchString, setSearchString] = useState(null);
  const [DropDown, setDropDown] = useState("shipmentId");
  const currentLang = useSelector((state) => state.lang);
  const [value, setValue] = useState([
    moment()
      .startOf("quarter")
      .format("YYYY-MM-DD"),
    moment()
      .endOf("quarter")
      .format("YYYY-MM-DD"),
  ]);
  let input;
  const { t } = useTranslation(["common", "shipment"]);
  const handleChange = () => {
    //Set data to State Here
    props.getSearchResult(input.value);
  };

  const dropDownChanger = (e) => {
    props.statusChange(e);
  };
  const handletoFromChange = (Datevalue) => {
    let startDate = moment(Datevalue[0]).format("YYYY-MM-DD");
    let endDate = moment(Datevalue[1]).format("YYYY-MM-DD");
    setValue([startDate, endDate]);
  };

  useEffect(() => {
    if (value) {
      props.dateRange(value);
    }
  }, [value]);

  const classes = useStyles();
  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div>
            <input
              className="form-control"
              ref={(n) => (input = n)}
              type="text"
              // onChange={handleChange}
              onChange={(e) => setSearchString(e.target.value)}
              style={{ maxWidth: "200px", marginRight: "15px" }}
              placeholder={`${t("shipment:shipment_id")}-${t(
                "shipment:order_id"
              )}`}
            />
          </div>
          <div style={{ marginLeft: ".7rem" }}>
            <FormControl className={classes.margin}>
              <NativeSelect
                id="demo-customized-select-native"
                input={<BootstrapInput />}
                style={{ minWidth: "110px", marginRight: "15px" }}
                onChange={(e) => setDropDown(e.target.value)}
              >
                <option value={"shipmentId"}>
                  {t("shipment:shipment_id")}
                </option>
                <option value={"orderId"}>{t("shipment:order_id")}</option>
              </NativeSelect>
            </FormControl>
          </div>
          <div style={{ marginTop: ".1rem" }}>
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              onClick={() => props.getSearchResult(SearchString, DropDown)}
            >
              {" "}
              {t("shipment:buttons.search")}
            </button>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <DateRangePicker
              locale={moment.locale(currentLang.lang)}
              clearIcon={null}
              format={"y/MM/dd"}
              onChange={(d) => handletoFromChange(d)}
              value={value}
              calendarIcon={<EventNoteIcon />}
              calendarAriaLabel={"Amit"}
            />
          </div>

          <div style={{ marginLeft: "1rem" }}>
            <FormControl className={classes.margin}>
              <NativeSelect
                id="demo-customized-select-native"
                input={<BootstrapInput />}
                onChange={(e) => dropDownChanger(e.target.value)}
              >
                <option value={"All"}>{t("common:status.ALL")}</option>
                <option value={"RECEIVED"}>
                  {t("common:status.RECEIVED")}
                </option>
                <option value={"SHIPPED"}>{t("common:status.SHIPPED")}</option>
              </NativeSelect>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

const RemoteTable = React.forwardRef((props, ref) => {
  const {
    data,
    columns,
    onTableChange,
    page,
    sizePerPage,
    totalSize,
    getSearchResult,
    cellEditProps,
    dateRange,
    statusChange,
    options,
  } = props;
  return (
    <div>
      <ToolkitProvider
        keyField="_id"
        data={data}
        columns={columns}
        getSearchResult={getSearchResult}
        statusChange={statusChange}
        dateRange={dateRange}
        search
      >
        {(toolkitprops) => [
          <span>
            <MySearch
              getSearchResult={getSearchResult}
              statusChange={statusChange}
              dateRange={dateRange}
              {...toolkitprops.searchProps}
            />
          </span>,
          <BootstrapTable
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: true,
              Search: true,
            }}
            keyField="_id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical`-center"
            pagination={paginationFactory({
              page,
              sizePerPageList: [25, 50, 75, 100],
              totalSize,
            })}
            ref={ref}
            cellEdit={cellEditFactory(cellEditProps)}
            noDataIndication={() => {
              return <div style={{ textAlign: "center" }}>{options}</div>;
            }}
          />,
        ]}
      </ToolkitProvider>
    </div>
  );
});

function ShipmentTable() {
  const [showModal, setShowModal] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const [Loader, setLoader] = useState(false);
  const [EditedCellValue, setEditedCellValue] = useState(null);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertData, setAlertData] = useState(null);
  const [ProductModalData, setProductModalData] = useState(null);
  const [showProductModal, setProductModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [showOrderModal, setOrderModal] = useState(false);
  const [OrderModalData, setOrderModalData] = useState(null);
  const refs = useRef();
  const [From, setFrom] = useState(null);
  const [To, setTo] = useState(null);
  const [Status, setStatus] = useState(null);

  const { t } = useTranslation(["common", "shipment"]);
  // Error & Success Message
  const [Error, setError] = useState({
    data: null,
    visibility: false,
  });
  const [Success, setSuccess] = useState({
    data: null,
    visibility: false,
  });
  // Successs Message

  const [editCustom, setEditCustom] = useState(false);

  const [state, setstate] = useState({
    page: 1,
    data: null,
    totalSize: 0,
    sizePerPage: 25,
  });
  const cellEditProps = {
    mode: "dbclick",
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      if (oldValue !== newValue) {
      }
    },
    beforeSaveCell: (oldValue, newValue, row, column) => {},
  };
  const [CustomId, setCustomId] = useState(null);

  const hideProductModal = () => {
    setProductModal(false);
  };

  useEffect(() => {
    getData(
      1,
      25,
      Status,
      moment()
        .startOf("quarter")
        .format("YYYY-MM-DD"),
      moment()
        .endOf("quarter")
        .format("YYYY-MM-DD")
    ).then((res) => {
      const numberOfProducts = res.data.totalCount;

      setstate((prevState) => {
        return {
          ...state,
          totalSize: numberOfProducts,
        };
      });
      if (res.data.data) {
        setstate((prevState) => {
          return {
            ...prevState,
            data: res.data.data,
          };
        });
      }
    });
  }, [editCustom]);

  const columns = [
    {
      dataField: "shipmentId",
      text: `${t("shipment:shipment_id")}`,
      // headerFormatter: (column, colIndex) => <>{column.text}</>,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cellContent, row) => (cellContent ? cellContent : "-"),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      // hidden: true,
    },
    {
      dataField: "shippingDate",
      text: `${t("shipment:shipping_date")}`,
      // headerFormatter: (column, colIndex) => <>{column.text}</>,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cellContent, row) => (cellContent ? cellContent : "-"),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      // hidden: true,
    },
    {
      dataField: "shipmentTrackingLink",
      text: `${t("shipment:tracking_link")}`,
      formatter: () => (
        <Button variant="outlined" color="primary" size="small">
          {t("shipment:buttons.open")}
        </Button>
      ),
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", textAlign: "center" };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          window.open(row.shipmentTrackingLink);
        },
      },
    },
    {
      dataField: "totalShippingFee",
      text: `${t("shipment:total_fee")}`,
      // headerFormatter: (column, colIndex) => <>{column.text}</>,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cellContent, row) => (cellContent ? "¥" + cellContent : "-"),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      // hidden: true,
    },
    {
      dataField: "customId",
      text: `${t("shipment:custom_id")}`,
      // headerFormatter: (column, colIndex) => <>{column.text}</>,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cellContent, row) => (cellContent ? cellContent : "-"),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      // hidden: true,
    },
    {
      dataField: "totalCustomFee",
      text: `${t("shipment:custom_fee")}`,
      // headerFormatter: (column, colIndex) => <>{column.text}</>,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cellContent, row) => (cellContent ? "₹" + cellContent : "-"),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      // hidden: true,
    },
    {
      dataField: "receivedDate",
      text: `${t("shipment:received_date")}`,
      // headerFormatter: (column, colIndex) => <>{column.text}</>,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "150px", textAlign: "center" };
      },
      formatter: (cellContent, row) => (cellContent ? cellContent : "-"),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      // hidden: true,
    },
    // {
    //   dataField: "customId",
    //   text: `CustomId`, //Stock quantity
    //   // headerFormatter: (column, colIndex) => <>{column.text}</>,
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       setClickedRow(row);
    //     },
    //   },
    //   headerFormatter: (column, colIndex) => {
    //     return (
    //       <React.Fragment>
    //         <div style={{ display: "flex", justifyContent: "space-between" }}>
    //           CustomId
    //           <FaEdit
    //             style={{ marginLeft: "1px", marginTop: "1px", size: "3px" }}
    //           />
    //         </div>
    //       </React.Fragment>
    //     );
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { minWidth: "80px", textAlign: "center" };
    //   },
    //   headerAlign: "center",
    //   align: "center",
    //   sort: true,
    //   formatter: (cellContent, row) => (cellContent ? cellContent : "N/A"),
    //   style: {
    //     "font-size": "12px",
    //     fontWeight: "normal",
    //   },
    // },
  ];

  const Search = (SearchString, SearchField) => {
    if (SearchString != "" || SearchString == " ") {
      if (SearchField === "shipmentId") {
        searchShippingId(1, 50, SearchString).then((res) => {
          const { data, totalCount } = res.data;
          console.log(data);
          setstate((prevstate) => {
            return {
              ...prevstate,
              data: data,
              totalSize: totalCount,
              page: 1,
              sizePerPage: 50,
            };
          });
        });
      } else {
        getData(1, 25).then((res) => {
          const numberOfProducts = res.data.totalCount;
          let newData = [];

          let array = res.data.data.map((ship) => {
            for (let i = 0; i < ship.shippedOrders.length; i++) {
              if (ship.shippedOrders[i].orderId === SearchString) {
                return ship;
              }
            }
            return 0;
          });
          for (let j = 0; j < array.length; j++) {
            if (array[j] !== 0) {
              newData.push(array[j]);
            }
          }
          console.log(newData);
          setstate((prevState) => {
            return {
              ...state,
              totalSize: numberOfProducts,
            };
          });
          if (res.data.data) {
            setstate((prevState) => {
              return {
                ...prevState,
                data: newData,
              };
            });
          }
        });
      }
    } else {
      getData(
        1,
        25,
        Status,
        moment()
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("quarter")
          .format("YYYY-MM-DD")
      ).then((res) => {
        const numberOfProducts = res.data.totalCount;

        setstate((prevState) => {
          return {
            ...state,
            totalSize: numberOfProducts,
          };
        });
        if (res.data.data) {
          setstate((prevState) => {
            return {
              ...prevState,
              data: res.data.data,
            };
          });
        }
      });
    }
  };

  const shipmentColumn = {
    dataField: "shipment",
    text: `${t("shipment:shipment")}`,
    formatter: () => (
      <Button variant="outlined" color="primary" size="small">
        {t("shipment:buttons.add_customs")}
      </Button>
    ),
    editable: false,
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { minWidth: "80px", textAlign: "center" };
    },
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        if (state.data) {
          state.data.map((ship) => {
            if (ship._id === row._id) {
              setProductModalData({
                totalCustomFee: row.totalCustomFee,
                customId: row.customId,
                receivedDate: row.receivedDate,
                shipId: ship._id,
              });
              setProductModal(true);
              console.log(row.product);
            }
          });
        }
      },
    },
  };

  const orderColumn = {
    dataField: "shippedOrders",
    text: `${t("shipment:orders")}`,
    formatter: () => (
      <Button variant="outlined" color="primary" size="small">
        {t("shipment:buttons.view")}
      </Button>
    ),
    editable: false,
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { minWidth: "80px", textAlign: "center" };
    },
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        if (state.data) {
          state.data.map((ship) => {
            if (ship) {
              if (ship._id === row._id) {
                // setProductModalData(row.product);
                // setProductModal(true);
                // console.log(row.product);
                console.log(ship.shippedOrders);
                // const orderids = ship.shippedOrders;
                // Promise.all(
                //   orderids.map(async (id) => {
                //     const res = await getOrderById(id);
                //     console.log(res.data.data);
                //     return res.data.data;
                //   })
                // ).then((orders) => {
                //   setOrderModalData(orders);
                //   setOrderModal(true);
                //   console.log(orders);
                // });
                setOrderModalData(ship.shippedOrders);
                setOrderModal(true);
              }
              // console.log(order.shippedOrders);
            } else {
              console.log("Order not present");
            }
          });
        }
      },
    },
  };

  const found =
    JSON.stringify(columns).indexOf(JSON.stringify(shipmentColumn)) > -1;

  const orderFound =
    JSON.stringify(columns).indexOf(JSON.stringify(orderColumn)) > -1;

  if (!orderFound && state.data) {
    columns.splice(4, 0, orderColumn);
  }

  if (!found && state.data) {
    columns.splice(8, 0, shipmentColumn);
  }

  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    getData(page, sizePerPage, Status, From, To).then((res) => {
      const numberOfProducts = res.data.totalCount;

      setstate((prevState) => {
        return {
          ...state,
          totalSize: numberOfProducts,
        };
      });
      if (res.data.data) {
        setstate((prevState) => {
          return {
            ...prevState,
            data: res.data.data,
            page,
            sizePerPage,
          };
        });
      }
    });

    if (type === "cellEdit") {
      //TODO: Save the data to db
      if (cellEdit["dataField"] === "activeStatus") {
        // setEditedCellValue({
        //   ...clickedRow,
        //   activeStatus: cellEdit["newValue"],
        // });
        // setstatusRow(true);
      } else if (cellEdit["dataField"] === "purchasePrice") {
      } else if (cellEdit["dataField"] === "category") {
      } else if (cellEdit["dataField"] === "customId") {
        setEditedCellValue({
          ...clickedRow,
          customId: cellEdit["newValue"],
        });
        setCustomId(true);
      } else {
      }
    }
  };

  const onCloseCellEditModal = () => {
    // const newState = state.data.map((p) => {
    //   if (p._id === EditedCellValue._id) {
    //     p.customId = EditedCellValue.customId;
    //     EditedCellValue.totalCustomFee = EditedCellValue.totalCustomFee;
    //     EditedCellValue.receivedDate = EditedCellValue.receivedDate;
    //     return p;
    //   }
    //   return p;
    // });
    // setstate((prevState) => {
    //   return { ...prevState, data: newState };
    // });
    // setCustomId(false);
    // console.log(EditedCellValue);
    // setLoader(true);
    // updateProductRow(EditedCellValue, EditedCellValue._id)
    //   .then((res) => {
    //     setSuccess((prevState) => {
    //       return {
    //         visibility: true,
    //         data: "Row Updated",
    //       };
    //     });
    //     setLoader(false);
    //   })
    //   .catch((err) => {
    //     if (err) {
    //       setError((prevState) => {
    //         return {
    //           visibility: true,
    //           data: "Something Went wrong",
    //         };
    //       });
    //     }
    //   });
  };

  function UpdateStateData(oldState, updatedData) {
    const tempArray = oldState;
    const index = tempArray.findIndex((x) => x._id === updatedData._id);
    tempArray[index] = updatedData;
    setstate((prevstate) => {
      return {
        ...prevstate,
        data: tempArray,
      };
    });
  }

  const ConfirmedShipmentId = (values) => {
    let CustomId = values.CustomId;
    let CustomFees = values.CustomFees;
    let ReceivedDate = values.ReceivedDate;

    if (CustomId !== "" && ReceivedDate) {
      setLoading(true);
      const body = {
        customId: CustomId,
        totalCustomFee: Number(CustomFees),
        receivedDate: ReceivedDate,
      };
      console.log(body);
      updateShipmentRow(body, ProductModalData.shipId)
        .then((res) => {
          setLoading(false);
          setProductModal(false);
          setSuccess((prevState) => {
            return {
              visibility: true,
              data: `${t("shipment:row_update")}`,
            };
          });
          setEditCustom(!editCustom);
          // if (res.data.data) {
          //   res.data.data.map((order) => {
          //     UpdateStateData(state.data, order);
          //   });
          // }
        })
        .catch((err) => {
          setLoading(false);
          if (err) {
            setError((prevState) => {
              return {
                visibility: true,
                data: `${t("shipment:wrong")}`,
              };
            });
          }
        });
    }
  };

  useEffect(() => {
    if (Success.visibility || Error.visibility) {
      setTimeout(() => {
        setSuccess((prevState) => {
          return {
            visibility: false,
            data: null,
          };
        });
        setError((prevState) => {
          return {
            visibility: false,
            data: null,
          };
        });
      }, 2000);
    }
  }, [Success, Error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const hideOrderModal = () => {
    setOrderModal(false);
  };

  const classes = useStyles();

  // -----------------------------SETTING STATUS AND DATE RANGE--------------------------------------

  useEffect(() => {
    getData(state.page, state.sizePerPage, Status, From, To).then((res) => {
      const numberOfProducts = res.data.totalCount;

      setstate((prevState) => {
        return {
          ...state,
          totalSize: numberOfProducts,
        };
      });
      if (res.data.data) {
        setstate((prevState) => {
          return {
            ...prevState,
            data: res.data.data,
          };
        });
      }
    });
  }, [Status]);

  useEffect(() => {
    getData(state.page, state.sizePerPage, Status, From, To).then((res) => {
      const numberOfProducts = res.data.totalCount;

      setstate((prevState) => {
        return {
          ...state,
          totalSize: numberOfProducts,
        };
      });
      if (res.data.data) {
        setstate((prevState) => {
          return {
            ...prevState,
            data: res.data.data,
          };
        });
      }
    });
  }, [From, To]);

  const rangeChanger = (value) => {
    if (value.length) {
      setFrom(value[0]);
      setTo(value[1]);
    }
  };

  const statusChanger = (e) => {
    setStatus(e);
  };

  // -----------------------------SETTING STATUS AND DATE RANGE--------------------------------------

  return (
    <React.Fragment>
      {ShowAlert && AlertData && (
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
          {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
          <p>{AlertData}</p>
        </Alert>
      )}

      {Error.visibility && Error.data && (
        <Snackbar
          open={Error.visibility}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity="error">{Error.data}</Alert>
        </Snackbar>
      )}

      {Success.visibility && Success.data && (
        <Snackbar
          open={Success.visibility}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity="success">{Success.data}</Alert>
        </Snackbar>
      )}
      {CustomId && (
        <ConfirmationModal
          showModal={CustomId}
          hideModal={() => setCustomId(false)}
          onSubmit={onCloseCellEditModal}
          fields={"CustomId"}
          loading={Loader}
        />
      )}
      {showOrderModal && OrderModalData && (
        <OrderModal
          showModal={showOrderModal}
          hideModal={hideOrderModal}
          data={OrderModalData}
        />
      )}
      {showProductModal && (
        <ShipmentModal
          showModal={showProductModal}
          hideModal={hideProductModal}
          data={ProductModalData}
          onSubmit={ConfirmedShipmentId}
          loading={Loading}
        />
      )}
      {state.data && (
        <React.Fragment>
          <RemoteTable
            data={state.data}
            page={state.page}
            sizePerPage={state.sizePerPage}
            totalSize={state.totalSize}
            columns={columns}
            onTableChange={handleTableChange}
            cellEditProps={cellEditProps}
            getSearchResult={Search}
            ref={refs}
            dateRange={rangeChanger}
            statusChange={statusChanger}
            options={t("common:noData")}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ShipmentTable;
