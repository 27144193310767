import React, { useState, useEffect } from "react";
import { makeStyles, TextField, IconButton, Button } from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import * as action from "../../../../redux/actions/index";
import { Formik, Form } from "formik";
import { CategoryTable } from "./CategoryTable/CategoryTable";
import * as requestFromServer from "../../../network/api";
import { shallowEqual } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function Category() {
  const { t } = useTranslation(["setting"]);
  const [Loading, setLoading] = useState(false);
  const [addCategory, setAddCategory] = useState("");
  const [snackBar, setSnackBar] = useState(false);
  const [state, setstate] = useState(false);

  const [showErrorSnackBar, setshowErrorSnackBar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const { currentState } = useSelector((state) => ({
    currentState: state.category,
  }));
  const dispatch = useDispatch();
  const { category, totalCount } = currentState;

  useEffect(() => {
    requestFromServer.fetchCategoryCount().then((res) => {
      dispatch(action.setTotalCount(res.data["totalCount"]));

      requestFromServer.fetchCategory(1, 10).then((res) => {
        let DataArray = [];
        res.data["data"].map((item) => {
          DataArray.push(item);
        });
        dispatch(action.createCategory(DataArray));
      });
    });

    return () => {};
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (addCategory != "") {
      setLoading(true);
      requestFromServer
        .saveCategory(addCategory)
        .then((res) => {
          setSnackBar(true);
          setAddCategory("");
          setLoading(false);
          dispatch(action.updateCategory(res.data["data"]));
          requestFromServer.fetchCategoryCount().then((res) => {
            dispatch(action.setTotalCount(res.data["totalCount"]));
          });
        })
        .catch((err) => {
          if (err) {
            setLoading(false);
            // err.response.data.message
            setshowErrorSnackBar(true);
            setErrorMessage(err.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (category) {
      if (category.length > 0) {
        setstate(true);
      }
    }
  }, [category, totalCount]);

  const handleChange = (event) => {
    event.preventDefault();
    setAddCategory(event.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowErrorSnackBar(false);
    setSnackBar(false);
  };

  const classes = useStyles();
  return (
    <div className="cat-wrapper row">
      <div className="col-4">
        <div className="cat-header">
          {/* Add a Category */}
          <h3>{t("setting:category.addCategory")}</h3>
        </div>
        <div className="cat-form">
          <Formik>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              {/* Enter category name：カテゴリー名を入力ください */}
              <TextField
                id="outlined-textarea"
                label={t("setting:category.enterCategory")}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                value={addCategory}
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={Loading}
              >
                {Loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {/* Add = 追加 */}
                {t("setting:category.addTo")}
                <AddIcon className={classes.rightIcon} />
              </Button>
            </Form>
          </Formik>
          {/* <form noValidate autoComplete="off" onChange={handleChange}></form> */}
        </div>
      </div>
      <div>
        <Snackbar open={snackBar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {t("setting:category.NewCategoryAdded")}
          </Alert>
        </Snackbar>

        <Snackbar
          open={showErrorSnackBar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="warning">
            {ErrorMessage}
          </Alert>
        </Snackbar>
      </div>
      <div className="col-8">
        <Card>
          {/* Category List - カテゴリーリスト */}

          <CardHeader title={t("setting:category.categoryList")}>
            <CardHeaderToolbar>
              {/* <button type="button" className="btn btn-primary">
                商品追加
              </button> */}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>{state && <CategoryTable />}</CardBody>
        </Card>
      </div>
    </div>
  );
}
