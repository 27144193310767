import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
/* ---- English Lang Translations ---- */
import common_en from "./translations/en/common.json";
import orderManagement_en from "./translations/en/orderManagment.json";
import orderShipment_en from "./translations/en/orderShipment.json";
import product_en from "./translations/en/product.json";
import otzOrder_en from "./translations/en/otzOrder.json";
import salesManagement_en from "./translations/en/salesManagment.json";
import otzSales_en from "./translations/en/otzSales.json";
import addproduct_en from "./translations/en/addproduct.json";
import remarksmodal_en from "./translations/en/remarksmodal.json";
import setting_en from "./translations/en/settings.json";
import orderEntry_en from "./translations/en/orderEntry.json";
import shipment_en from "./translations/en/shipment.json";
import issues_en from "./translations/en/Issues.json";
import errors_en from "./translations/en/errors.json";

/* ---- Japanese translations ---- */
import common_jp from "./translations/jp/common.json";
import orderManagement_jp from "./translations/jp/orderManagment.json";
import orderShipment_jp from "./translations/jp/orderShipment.json";
import product_jp from "./translations/jp/product.json";
import otzOrder_jp from "./translations/jp/otzOrder.json";
import salesManagement_jp from "./translations/jp/salesManagment.json";
import otzSales_jp from "./translations/jp/otzSales.json";
import addproduct_jp from "./translations/jp/addproduct.json";
import remarksmodal_jp from "./translations/jp/remarksmodal.json";
import setting_jp from "./translations/jp/settings.json";
import orderEntry_jp from "./translations/jp/orderEntry.json";
import shipment_jp from "./translations/jp/shipment.json";
import issues_jp from "./translations/jp/Issues.json";
import errors_jp from "./translations/jp/errors.json";

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    lng: "en",
    fallbackLng: "en",
    // whitelist: ["en", "jp"],
    debug: true,
    languages: ["ja", "en"],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: common_en,
        ordermanagement: orderManagement_en,
        ordershipment: orderShipment_en,
        product: product_en,
        otzorder: otzOrder_en,
        salesmanagement: salesManagement_en,
        otzsales: otzSales_en,
        addproduct: addproduct_en,
        remarksmodal: remarksmodal_en,
        setting: setting_en,
        orderentry: orderEntry_en,
        shipment: shipment_en,
        issues: issues_en,
        errors: errors_en,
      },
      ja: {
        common: common_jp,
        ordermanagement: orderManagement_jp,
        ordershipment: orderShipment_jp,
        product: product_jp,
        otzorder: otzOrder_jp,
        salesmanagement: salesManagement_jp,
        otzsales: otzSales_jp,
        addproduct: addproduct_jp,
        remarksmodal: remarksmodal_jp,
        setting: setting_jp,
        orderentry: orderEntry_jp,
        shipment: shipment_jp,
        issues: issues_jp,
        errors: errors_jp,
      },
    },
  });

export default i18n;
