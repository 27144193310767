import * as actionsType from "./actionsType";
import * as requestFromServer from "../../app/network/api";

export const createshippingMethod = (shippingmethod) => {
  return {
    type: actionsType.CREATE_SHIPPINGMETHOD,
    payload: shippingmethod,
  };
};

export const fetchshippingMethodSuccess = (fetchedCat) => {
  return {
    type: actionsType.FETCH_SHIPPINGMETHOD_SUCCESS,
    payload: fetchedCat,
  };
};

export const initshippingMethod = (initFetch) => {
  return {
    type: actionsType.INIT_SHIPPINGMETHOD,
    payload: initFetch,
  };
};

export const updateshippingMethod = (data) => {
  return {
    type: actionsType.UPADTE_SHIPPINGMETHOD,
    payload: data,
  };
};

export const isLoading = (data) => {
  return {
    type: actionsType.IS_LOADING,
    payload: data,
  };
};

export const setTotalShippingCount = (data) => {
  return {
    type: actionsType.TOTALSHIPPINGMETHODCOUNT,
    payload: data,
  };
};
