import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";

function ProductModal({ data, showModal, hideModal }) {
  const { t } = useTranslation(["common"]);
  const [Data, setData] = useState({
    productName: null,
    SKU: null,
    Category: null,
    Manufacturer: null,
    JanCode: null,
    Supplier: null,
    StockQuantity: null,
    ShelfNumber: null,
  });
  const [TableData, setTableData] = useState(null);

  useEffect(() => {
    const {
      category,
      manufacturer,
      name,
      janCode,
      skuCode,
      inStockQuantity,
      shelfNumber,
    } = data;

    if (category) {
      setData((prevData) => {
        return {
          ...prevData,
          Category: category["name"],
        };
      });
    }
    if (inStockQuantity) {
      setData((prevData) => {
        return {
          ...prevData,
          StockQuantity: inStockQuantity,
        };
      });
    }
    if (manufacturer) {
      setData((prevData) => {
        return {
          ...prevData,
          Manufacturer: manufacturer["name"],
        };
      });
    }
    if (name) {
      setData((prevData) => {
        return {
          ...prevData,
          productName: name,
        };
      });
    }

    if (janCode) {
      setData((prevData) => {
        return {
          ...prevData,
          JanCode: janCode,
        };
      });
    }

    if (skuCode) {
      setData((prevData) => {
        return {
          ...prevData,
          SKU: skuCode,
        };
      });
    }
    if (shelfNumber) {
      setData((prevData) => {
        return {
          ...prevData,
          ShelfNumber: shelfNumber,
        };
      });
    }
  }, [data]);

  useEffect(() => {
    const Table = [];
    if (Data.productName) {
      for (let key in Data) {
        if (Data[key]) {
          Table.push({
            productproperty: `${t("common:productmodal." + key)}`,
            Details: Data[key],
          });
        }
      }
    }
    setTableData(Table);
  }, [Data]);

  const columns = [
    {
      dataField: "productproperty",
      text: `${t("common:productmodal.productproperty")}`,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", textAlign: "center" };
      },
    },
    {
      dataField: "Details",
      text: `${t("common:productmodal.productdetails")}`,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", textAlign: "center" };
      },
    },
  ];

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{t("common:productmodal.product")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {TableData && (
            <BootstrapTable keyField="_id" data={TableData} columns={columns} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hideModal()}>
            {t("common:close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductModal;
