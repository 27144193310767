import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "react-date-picker";
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function ShipmentModal({
  showModal,
  hideModal,
  onSubmit,
  Fields,
  data,
  loading,
}) {
  const classes = useStyles();
  const [CustomId, setCustomId] = useState(null);
  const [CustomFees, setCustomFees] = useState(0);
  const [ReceivedDate, setReceivedDate] = useState("");
  const { t } = useTranslation(["common", "shipment"]);

  const handleChange = (e) => {
    setCustomId(e.target.value);
  };

  const dateChange = (e) => {
    setReceivedDate(moment(e).format("YYYY-MM-DD"));
  };

  const feeChange = (e) => {
    setCustomFees(e.target.value);
  };

  const format = function date2str(x, y) {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function(v) {
      return x
        .getFullYear()
        .toString()
        .slice(-v.length);
    });
  };

  useEffect(() => {
    if (data.receivedDate) {
      setReceivedDate(new Date(data.receivedDate));
    } else {
      setReceivedDate(format(new Date(), "yyyy-MM-dd"));
    }
  }, [data.receivedDate]);
  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("shipment:enter_custom_info")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Body */}
          <div
          // style={{
          //   textAlign: "center",
          // }}
          >
            <div>
              <TextField
                value={data.customId}
                id="standard-basic"
                label={t("shipment:custom_id")}
                fullWidth
                onChange={handleChange}
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingBottom: 0,
                  marginTop: 0,
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              />
            </div>
            <div>
              <TextField
                value={data.totalCustomFee}
                id="standard-basic"
                label={t("shipment:custom_fee")}
                type="number"
                fullWidth
                onChange={feeChange}
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: "20px",
                  fontWeight: 500,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="shipment">
              {/* <form className={classes.container} noValidate> */}
              <label style={{ fontSize: "10px", color: "#757575 " }}>
                {t("shipment:received_date")}
              </label>
              <br />
              <DatePicker
                onChange={(e) => dateChange(e)}
                value={ReceivedDate ? new Date(ReceivedDate) : new Date()}
                clearIcon={null}
                calendarIcon={null}
                format={"y/MM/dd"}
                fullWidth
              />
              {/* <TextField
                  value={data.receivedDate}
                  id="date"
                  type="date"
                  label="Received Date"
                  fullWidth
                  onChange={dateChange}
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingBottom: 0,
                    marginTop: 0,
                    fontWeight: 500,
                  }}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
              {/* </form> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => hideModal()}
          >
            {t("common:close")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={data.customId}
            className={classes.button}
            onClick={() => onSubmit({ CustomId, CustomFees, ReceivedDate })}
            startIcon={<SaveIcon fontSize={"small"} />}
          >
            {loading && (
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {t("common:save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
