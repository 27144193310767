import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import cellEditFactory from "react-bootstrap-table2-editor";
import * as action from "../../../../redux/actions/index";
import { Type } from "react-bootstrap-table2-editor";
import axios from "axios";
import * as requestFromServer from "../../../network/api";
import { columns } from "./ShippingMethodFields";
import Button from "react-bootstrap/Button";
import Table from "../Components/Table";
import Modal from "../Components/SettingModal";
import { useTranslation } from "react-i18next";
import { PleaseWaitMessage } from "../../../../_metronic/_helpers";
import moment from "moment";
export default function ShippingMethodTable() {
  const { t } = useTranslation(["setting"]);
  const dispatch = useDispatch();
  const [showModal, setshowModal] = useState(false);
  const [changedData, SetChangedData] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, sizePerPage: 10 });
  const { currentState } = useSelector((state) => ({
    currentState: state.shippingMethod,
  }));

  const { shippingMethod, totalCount } = currentState;
  const [state, setState] = useState({
    page: 1,
    data: shippingMethod,
    totalSize: totalCount,
    sizePerPage: 10,
  });

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, cellEdit }
  ) => {
    requestFromServer.fetchshippingMethod(page, sizePerPage).then((res) => {
      setPagination((prevState) => {
        return {
          ...prevState,
          page: page,
          sizePerPage: sizePerPage,
        };
      });
      let DataArray = [];
      res.data["data"].map((item) => DataArray.push(item));

      dispatch(action.createshippingMethod(DataArray));

      setState((prevState) => {
        return {
          ...prevState,
          page: page,
          data: DataArray,
          totalSize: totalCount,
          sizePerPage: sizePerPage,
        };
      });

      if (sortField) {
        requestFromServer
          .sortShippingMethod(sortField, sortOrder, sizePerPage, page)
          .then((res) => {
            setPagination((prevState) => {
              return {
                ...prevState,
                page: page,
                sizePerPage: sizePerPage,
              };
            });
            let DataArray = [];
            res.data["data"].map((item) => DataArray.push(item));

            dispatch(action.createshippingMethod(DataArray));

            setState((prevState) => {
              return {
                ...prevState,
                page: page,
                data: DataArray,
                totalSize: totalCount,
                sizePerPage: sizePerPage,
              };
            });
          });
      }
    });
  };

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        page: pagination.page,
        data: shippingMethod,
        totalSize: totalCount,
        sizePerPage: pagination.sizePerPage,
      };
    });
  }, [shippingMethod, totalCount, pagination.page, pagination.sizePerPage]);

  const savecell = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {
      setshowModal(true);
      SetChangedData({ oldValue, newValue, row, column });
    }
  };
  const onSubmit = () => {
    console.log("onSubmit");
    setshowModal(false);
    if (changedData) {
      const oldValue = changedData["oldValue"];
      const newValue = changedData["newValue"];
      const row = changedData["row"];
      const column = changedData["column"];
      requestFromServer.updateshippingMethod(newValue, row._id).then((res) => {
        const MappedArray = shippingMethod.map(function(item) {
          return item._id == res.data["data"]._id ? res.data["data"] : item;
        });
        setState((prevState) => {
          return {
            ...prevState,
            data: MappedArray,
          };
        });
      });
    }
  };

  const hideModal = () => {
    console.log("hideModal");
    setshowModal(false);
  };

  const getSearchResult = (value) => {
    console.log(value);
  };

  // --------------------------------------------------------------------------------------------------------------
  const dateFormatter = (cell, row) => {
    if (cell) {
      var a = new Date(cell);
      return `${a.getUTCFullYear()}/${a.getUTCMonth()}/${a.getUTCDate()}`;
    }
    return cell;
  };
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "name",
      // Category Name - カテゴリー名
      text: `${t("setting:shippingMethod.shippingmethodName")}`,
      headerAlign: "center",
      align: "center",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "createdAt",
      // Created On - 作成日
      text: `${t("setting:shippingMethod.createdDate")}`,
      editable: false,
      formatter: (cell, row) => {
        if (cell) {
          return moment(cell).format("YYYY-MM-DD");
        }
      },
      headerAlign: "center",
      align: "center",
      sort: true,
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
  ];
  // --------------------------------------------------------------------------------------------------------------

  var options = {
    noDataText: "Your_custom_text",
  };

  return (
    <>
      <Modal
        showModal={showModal}
        hideModal={hideModal}
        onSubmit={onSubmit}
        field={"Supplier Methods"}
      />
      <Table
        columns={columns}
        handleTableChange={handleTableChange}
        state={state}
        saveCell={savecell}
        getSearchResult={getSearchResult}
        options={options}
      />
    </>
  );
}
