import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../../../config";

const API_ENDPOINT = BASE_URL;

const getData = (page, limit, status, from, to) => {
  console.log(page, limit, status, from, to);
  if (status === "SHIPPED") {
    if (from && to) {
      return axios.get(
        `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt&status=SHIPPED&shippingDate[gte]=${from}&shippingDate[lte]=${to}`
      );
    } else {
      return axios.get(
        `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt&status=SHIPPED`
      );
    }
  } else if (status === "RECEIVED") {
    if (from && to) {
      return axios.get(
        `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt&status=RECEIVED&receivedDate[gte]=${from}&receivedDate[lte]=${to}`
      );
    } else {
      return axios.get(
        `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt&status=RECEIVED`
      );
    }
  } else if (status === "All") {
    if (from && to) {
      return axios.get(
        `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt&shippingDate[gte]=${from}&shippingDate[lte]=${to}`
      );
    } else {
      return axios.get(
        `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt`
      );
    }
  } else {
    if (!status) {
      if (from && to) {
        return axios.get(
          `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt&shippingDate[gte]=${from}&shippingDate[lte]=${to}`
        );
      } else {
        return axios.get(
          `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt`
        );
      }
    }
  }

  return axios.get(
    `${API_ENDPOINT}/shipments?page=${page}&limit=${limit}&sort=-updatedAt`
  );
};

const updateShipmentRow = (shipment, id) => {
  //Take the id and update in DB
  console.log(shipment);
  console.log("jjjj");
  return axios.put(`${API_ENDPOINT}/shipments/${id}`, { ...shipment });
};

const searchShippingId = (page, limit, ShipmentId) => {
  return axios.get(
    `${API_ENDPOINT}/shipments/search?searchStr=${ShipmentId}&page=${page}&limit=${limit}`
  );
};

export { getData, updateShipmentRow, searchShippingId };
