import * as actionType from "../actions/actionsType";
import { compare } from "../../app/view/Settings/Components/Utils";

const initialState = {
  supplier: [],
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_SUPPLIER:
      return {
        ...state,
        supplier: action.payload.sort(compare).reverse(),
      };
    case actionType.INIT_SUPPLIER:
      return {
        ...state,
        supplier: state.supplier
          .concat(action.payload)
          .sort(compare)
          .reverse(),
      };
    case actionType.UPADTE_SUPPLIER:
      return {
        ...state,
        supplier: state.supplier
          .concat(action.payload)
          .sort(compare)
          .reverse(),
      };

    case actionType.UPADTE_SUPPLIER_CELL:
      return {
        ...state,
        category: action.payload,
      };
    case actionType.IS_LOADING:
      return {
        ...state,
        listLoading: action.payload,
      };
    case actionType.TOTALSUPPLIERCOUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
