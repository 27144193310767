import * as actionsType from "./actionsType";
import * as requestFromServer from "../../app/network/api";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const createManufacturer = (manufacturer) => {
  return {
    type: actionsType.CREATE_MANUFACTURER,
    payload: manufacturer,
  };
};

export const fetchManufacturerSuccess = (fetchedCat) => {
  return {
    type: actionsType.FETCH_MANUFACTURER_SUCCESS,
    payload: fetchedCat,
  };
};

export const initManufacturer = (initFetch) => {
  return {
    type: actionsType.INIT_MANUFACTURER,
    payload: initFetch,
  };
};

export const updateManufacturer = (data) => {
  return {
    type: actionsType.UPADTE_MANUFACTURER,
    payload: data,
  };
};

export const isLoading = (data) => {
  return {
    type: actionsType.IS_LOADING,
    payload: data,
  };
};

export const setTotalManufacturerCount = (data) => {
  return {
    type: actionsType.TOTALMANUFACTURERCOUNT,
    payload: data,
  };
};

export const updateManufacturerCell = (data) => {
  return {
    type: actionsType.UPADTE_MANUFACTURER_CELL,
    payload: data,
  };
};
