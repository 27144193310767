import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Content } from "./Content";
import { Redirect, Switch, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { validateUser } from "../../../../app/modules/Auth/_redux/authCrud";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";

export function ContentRoute({ children, component, render, ...props }) {
  const dispatch = useDispatch();
  useEffect(() => {
    validateUser()
      .then((res) => {
        console.log("User Verified");
      })
      .catch((err) => {
        if (err) {
          dispatch(auth.actions.logout());
        }
      });
  });

  return (
    <>
      <Route {...props}>
        {(routeProps) => {
          if (typeof children === "function") {
            return <Content>{children(routeProps)}</Content>;
          }

          if (!routeProps.match) {
            return null;
          }

          if (children) {
            return <Content>{children}</Content>;
          }

          if (component) {
            return (
              <Content>{React.createElement(component, routeProps)}</Content>
            );
          }

          if (render) {
            return <Content>{render(routeProps)}</Content>;
          }

          return null;
        }}
      </Route>
    </>
  );
}
