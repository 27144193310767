import { stubFalse } from "lodash";
import * as actionType from "../actions/actionsType";

const initialState = {
  refresh: false,
  otzRefresh: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REFRESH_STATUS:
      return {
        ...state,
        refresh: action.payload,
      };
    case actionType.REFRESH_OTZ_STATUS:
      return {
        ...state,
        otzRefresh: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
