import React, { useEffect, useState } from "react";
import Table from "../Components/Table";
import * as requestFromServer from "../../../network/api";
import { useSelector, useDispatch } from "react-redux";
import * as action from "../../../../redux/actions/index";
import Modal from "../Components/SettingModal";
import { Type } from "react-bootstrap-table2-editor";
import { statusButtons } from "../Components/ViewButtons";
import { useTranslation } from "react-i18next";
import moment from "moment";
function HandlingChargesTable() {
  const { t } = useTranslation(["setting"]);
  const { currentState } = useSelector((state) => ({
    currentState: state.handlingCharges,
  }));
  const dispatch = useDispatch();
  const { handlingcharges, totalCount } = currentState;
  const [state, setState] = useState({
    page: 1,
    data: handlingcharges,
    totalSize: totalCount,
    sizePerPage: 10,
  });
  const [showModal, setshowModal] = useState(false);
  const [changedData, SetChangedData] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, sizePerPage: 10 });

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, cellEdit }
  ) => {
    requestFromServer.fetchHandlingCharge(page, sizePerPage).then((res) => {
      setPagination((prevState) => {
        return {
          ...prevState,
          page: page,
          sizePerPage: sizePerPage,
        };
      });
      let DataArray = [];
      res.data["data"].map((item) => DataArray.push(item));

      dispatch(action.createHandlingCharge(DataArray));

      setState((prevState) => {
        return {
          ...prevState,
          page: page,
          data: DataArray,
          totalSize: totalCount,
          sizePerPage: sizePerPage,
        };
      });
    });

    if (sortField) {
      requestFromServer
        .sortHandlingCharge(sortField, sortOrder, sizePerPage, page)
        .then((res) => {
          setPagination((prevState) => {
            return {
              ...prevState,
              page: page,
              sizePerPage: sizePerPage,
            };
          });
          let DataArray = [];
          res.data["data"].map((item) => DataArray.push(item));

          dispatch(action.createHandlingCharge(DataArray));

          setState((prevState) => {
            return {
              ...prevState,
              page: page,
              data: DataArray,
              totalSize: totalCount,
              sizePerPage: sizePerPage,
            };
          });
        });
    }
  };

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        page: pagination.page,
        data: handlingcharges,
        totalSize: totalCount,
        sizePerPage: pagination.sizePerPage,
      };
    });
  }, [handlingcharges, totalCount, pagination.page, pagination.sizePerPage]);

  const getSearchResult = (e) => {
    console.log(e);
  };

  const onSubmit = () => {
    if (changedData) {
      const oldValue = changedData["oldValue"];
      const newValue = changedData["newValue"];
      const row = changedData["row"];
      const column = changedData["column"].dataField;
      if (column === "value") {
        requestFromServer
          .updateHandlingChargePut(row._id, row.title, row.type, newValue)
          .then((res) => {
            const MappedArray = handlingcharges.map(function(item) {
              return item._id == res.data["data"]._id ? res.data["data"] : item;
            });
            setState((prevState) => {
              return {
                ...prevState,
                data: MappedArray,
              };
            });
            setshowModal(false);
          });
      } else {
        requestFromServer
          .updateHandlingChargePut(row._id, newValue, row.type, row.value)
          .then((res) => {
            const MappedArray = handlingcharges.map(function(item) {
              return item._id == res.data["data"]._id ? res.data["data"] : item;
            });
            setState((prevState) => {
              return {
                ...prevState,
                data: MappedArray,
              };
            });
            setshowModal(false);
          });
      }
    }
  };

  const hideModal = () => {
    console.log("hideModal");
    setshowModal(false);
  };
  const savecell = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {
      setshowModal(true);
      SetChangedData({ oldValue, newValue, row, column });
    }
  };
  // ----------------------------------------------------------------------------------------------------------
  const dateFormatter = (cell, row) => {
    if (cell) {
      var a = new Date(cell);
      return `${a.getUTCFullYear()}/${a.getUTCMonth()}/${a.getUTCDate()}`;
    }
    return cell;
  };

  const columns = [
    {
      dataField: "_id",
      text: "ID",
      editable: false,
      hidden: true,
    },
    {
      dataField: "title",
      // Category Name - カテゴリー名
      text: `${t("setting:hc.name")}`,
      editable: true,
      headerAlign: "center",
      align: "center",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "type",
      // Category Name - カテゴリー名
      text: `${t("setting:hc.type")}`,
      editable: false,
      headerAlign: "center",
      align: "center",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "value",
      // Category Name - カテゴリー名
      text: `${t("setting:hc.value")}`,
      editable: true,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "createdAt",
      // Created On - 作成日
      text: `${t("setting:hc.createdAt")}`,
      editable: false,
      formatter: (cell) => {
        if (cell) {
          return moment(cell).format("YYYY-MM-DD");
        }
      },
      headerAlign: "center",
      align: "center",
      sort: true,
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
  ];
  // ----------------------------------------------------------------------------------------------------------
  return (
    <div>
      <Modal
        showModal={showModal}
        hideModal={hideModal}
        onSubmit={onSubmit}
        field={"Handling Charges"}
      />
      <Table
        columns={columns}
        handleTableChange={handleTableChange}
        getSearchResult={getSearchResult}
        state={state}
        saveCell={savecell}
      />
    </div>
  );
}

export default HandlingChargesTable;
