import * as actionsType from "./actionsType";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const createCategory = (category) => {
  return {
    type: actionsType.CREATE_CATEGORY,
    payload: category,
  };
};

export const initCategory = (initFetch) => {
  return {
    type: actionsType.INIT_CATEGORY,
    payload: initFetch,
  };
};

export const updateCategory = (data) => {
  return {
    type: actionsType.UPADTE_CATEGORY,
    payload: data,
  };
};

export const isLoading = (data) => {
  return {
    type: actionsType.IS_LOADING,
    payload: data,
  };
};

export const setTotalCount = (data) => {
  return {
    type: actionsType.TOTALCOUNT,
    payload: data,
  };
};

export const updateCategoryCell = (data) => {
  return {
    type: actionsType.UPDATE_CATEGORY_CELL,
    payload: data,
  };
};
