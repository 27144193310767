import React from "react";
import {
  makeStyles,
  TextField,
  IconButton,
  Button,
  fade,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

export const Product_Button = () => (
  <Button variant="text" color="secondary" size="small">
    <VisibilityOutlinedIcon fontSize={"small"} color={"inherit"} />
  </Button>
);
