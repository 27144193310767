import * as actionType from "../actions/actionsType";
import { compare } from "../../app/view/Settings/Components/Utils";

const initialState = {
  newOrderTime: null,
  oldOrdersTime: null,
  notificationOrders: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.NEW_ORDER_TIME:
      return {
        ...state,
        newOrderTime: action.payload,
      };
    case actionType.PREVIOUS_ORDER_TIME:
      return {
        ...state,
        oldOrdersTime: action.payload,
      };
    case actionType.NOTIFICATION_ORDERS:
      return {
        ...state,
        notificationOrders: state.notificationOrders.concat(action.payload),
      };
    default:
      return state;
  }
};

export default reducer;
