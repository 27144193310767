import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import * as requestfromserver from "../../network/api";
import { ExportToCsv } from "export-to-csv";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const options = [
  { value: "KIWAMI", label: "KIWAMI" },
  { value: "AMAZONJP", label: "AMAZONJP" },
  { value: "AMAZONIND", label: "AMAZONIND" },
];
const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px 10px 12px",
    height: 17,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function CsvModal({ showModal, hideModal }) {
  const [JsonData, setJsonData] = useState(null);
  const [platforms, setplatforms] = useState(null);
  const [msg, setMsg] = useState({
    data: null,
    visibility: false,
  });
  const { t } = useTranslation(["common", "ordermanagement", "product"]);
  const [DownloadDates, setDownloadDates] = useState([
    moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
  ]);
  const classes = useStyles();
  const currentLang = useSelector((state) => state.lang);
  const DownloadData = () => {
    requestfromserver
      .downloadSalesCsv(DownloadDates[0], DownloadDates[1], platforms)
      .then((res) => {
        console.log(res.data.data);
        var asd = res.data.data;
        let mapped = asd.map((obj) => {
          var asin = "";
          const code = obj.platformCode;
          const availablePlatforms = obj.product.availablePlatforms;
          for (let i = 0; i < availablePlatforms.length; i++) {
            if (availablePlatforms[i].code === code) {
              asin = availablePlatforms[i].asin;
              break;
            }
          }
          const dat = {
            date: obj.orderDate,
            orderId: obj.orderId,
            skuCode: obj.product.skuCode,
            asin: asin,
            janCode: obj.product.janCode,
            orderQuantity: obj.orderQuantity,
            platformCode: obj.platformCode,
            baseOrderPrice: obj.baseOrderPrice,
            commission: obj.commission,
            shippingFee: obj.shippingFee,
            gst: obj.gst,
            handlingFee: obj.handlingFee,
            discount: obj.discount,
          };
          return dat;
        });
        const options = {
          filename: "sales-management",
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: false,
          useTextFile: false,
          useBom: true,
          // useKeysAsHeaders: true,
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          headers: [
            "DATE",
            "ORDER ID",
            "SKU CODE",
            "ASIN",
            "JAN CODE",
            "ORDER QUANTITY",
            "PLATFORM CODE",
            "BASE PRICE",
            "COMMISSION",
            "SHIPPING FEE",
            "TAX",
            "HANDLING FEE",
            "DISCOUNT",
          ],
        };
        if (asd.length > 0) {
          const csvExporter = new ExportToCsv(options);

          csvExporter.generateCsv(mapped);
        } else {
          console.log("empty");
          setMsg((prevState) => {
            return {
              visibility: true,
              data: `${t("ordermanagement:modals.empty_list")}`,
            };
          });
        }
      });
  };

  const handletoFromChange = (Datevalue) => {
    let dateArray = Datevalue;
    let startDate = moment(Datevalue[0]).format("YYYY-MM-DD");
    let endDate = moment(Datevalue[1]).format("YYYY-MM-DD");
    setDownloadDates([startDate, endDate]);
  };

  const handlePlatformChange = (e) => {
    if (e.target.value === "All") {
    } else {
      setplatforms(e.target.value);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <div>
      {msg.visibility && msg.data && (
        <Snackbar
          open={msg.visibility}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity="success">{msg.data}</Alert>
        </Snackbar>
      )}
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        backdrop="static"
        keyboard={false}
        style={{ Width: "70%" }}
      >
        <Modal.Header>
          <Modal.Title>{t("ordermanagement:csv_download")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <DateRangePicker
                clearIcon={null}
                format={"y/MM/dd"}
                onChange={(d) => handletoFromChange(d)}
                value={DownloadDates}
                EventNoteIcon
                calendarIcon={<EventNoteIcon />}
                calendarAriaLabel={"Amit"}
                locale={moment.locale(currentLang.lang)}
              />
            </div>
            <div>
              <FormControl className={classes.margin}>
                <NativeSelect
                  id="demo-customized-select-native"
                  onChange={handlePlatformChange}
                  input={<BootstrapInput />}
                >
                  <option value={"All"}>{t("common:status.ALL")}</option>
                  <option value={"SHOPIFY"}>Kiwami</option>
                  <option value={"AMAZONJP"}>AmazonJp</option>
                  <option value={"AMAZONIND"}>AmazonInd</option>
                </NativeSelect>
              </FormControl>
            </div>
            <div>
              <Button variant="primary" onClick={() => DownloadData()}>
                {t("common:download")}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hideModal()}>
            {t("product:modals.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CsvModal;

function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
}
