import React, { useState } from "react";
// import Button from "react-bootstrap/Button";
import { Modal, Spinner } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function ConfirmationModal({
  showModal,
  hideModal,
  onSubmit,
  fields,
  loading,
}) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please confirm the changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Body */}
          You are about to change {fields}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => hideModal()}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => onSubmit()}
            startIcon={<SaveIcon fontSize={"small"} />}
          >
            {loading && (
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
