import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import { Html5Entities } from "html-entities";
import kiwami from "./icon/kiwami.PNG";
import AmazonInd from "./icon/AmazonInd.png";
import AmazonJp from "./icon/AmazonJp.png";
import { useTranslation } from "react-i18next";

function OrderModal({ data, showModal, hideModal }) {
  const entities = new Html5Entities();
  const { t } = useTranslation(["common", "ordermanagement"]);
  const columns = [
    {
      dataField: "orderId",
      text: `${t("ordermanagement:table.orderID")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? <strong>{cellContent}</strong> : "-",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },

    {
      dataField: "orderDate",
      text: `${t("ordermanagement:table.OrderDate")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? <strong>{cellContent}</strong> : "-",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "platformCode",
      text: `${t("ordermanagement:table.EC")}`,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      //
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
      editable: false,
      formatter: (cellContent, row) => {
        switch (cellContent) {
          case "SHOPIFY":
            return (
              <img
                src={kiwami}
                className="svg-icon-lg svg-icon-primary notification-icon"
                alt="icon"
              />
            );
          case "AMAZONJP":
            return (
              <React.Fragment>
                <img
                  src={AmazonJp}
                  className="svg-icon-lg svg-icon-primary notification-icon"
                  alt="icon"
                />
              </React.Fragment>
            );
          case "AMAZONIND":
            return (
              <img
                src={AmazonInd}
                className="svg-icon-lg svg-icon-primary notification-icon"
                alt="icon"
              />
            );
          default:
            return "Unknown";
        }
      },
    },
    {
      dataField: "orderQuantity",
      text: `${t("ordermanagement:table.Quantity")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? <strong>{cellContent}</strong> : "-",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "product.name",
      text: `${t("ordermanagement:table.Product")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? <strong>{cellContent}</strong> : "-",
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "baseOrderPrice",
      text: `${t("ordermanagement:table.baseOrderPrice")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? (
          <strong>
            {entities.decode("&yen")}
            {cellContent}
          </strong>
        ) : (
          <strong>{entities.decode("&yen")}0</strong>
        ),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "platformFee",
      text: `${t("ordermanagement:table.PlatformFee")}`,
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { minWidth: "120px", textAlign: "center" };
      },
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <Button variant="text" color="secondary" size="small">
            {/* <IsoIcon fontSize={"small"} color={"inherit"} /> */}
            {entities.decode("&yen")}{" "}
            {parseInt(row.gst) +
              parseInt(row.commission) +
              parseInt(row.closingFee)}
          </Button>
        );
      },
      title: function callback(cell, row, rowIndex, colIndex) {
        // return { shippingFee: "3889", customFee: "0", handlin m  gFee: "32" };
        return `${t("ordermanagement:table.closingfee")}: ${
          row.closingFee
        }, ${t("ordermanagement:table.commission")}: ${row.commission}, ${t(
          "ordermanagement:table.tax"
        )}: ${row.gst}`;
      },
    },
    {
      dataField: "shippingFee",
      text: `${t("ordermanagement:table.ShippingFee")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? (
          <strong>
            {entities.decode("&yen")}
            {cellContent}
          </strong>
        ) : (
          <strong>{entities.decode("&yen")}0</strong>
        ),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "customFee",
      text: `${t("ordermanagement:table.Custom")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? (
          <strong>
            {entities.decode("&#8377")}
            {cellContent}
          </strong>
        ) : (
          <strong>{entities.decode("&#8377")}0</strong>
        ),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
    {
      dataField: "discount",
      text: `${t("ordermanagement:table.discount")}`,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) =>
        cellContent ? (
          <strong>
            {entities.decode("&yen")}
            {cellContent}
          </strong>
        ) : (
          <strong>{entities.decode("&yen")}0</strong>
        ),
      style: {
        "font-size": "12px",
        fontWeight: "normal",
      },
    },
  ];

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        backdrop="static"
        size="xl"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{t("ordermanagement:OrderManagement")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data && (
            <BootstrapTable keyField="_id" data={data} columns={columns} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hideModal()}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OrderModal;
