import * as actionType from "../actions/actionsType";

const initialState = {
  newOrder: false,
  newOTZOrder: false,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.NEW_EC_ORDER:
      return {
        ...state,
        newOrder: action.payload,
      };
    case actionType.NEW_OTZ_ORDER:
      return {
        ...state,
        newOTZOrder: action.payload,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
